import React, { useEffect } from 'react'
import EditViewProfile from './EditViewProfile'
import MyTabs from '../../components/tabs/MyTabs'
import { Box } from '@mui/material'
import NpiInfo from './NpiInfo'
import FindNpiDetails from './FindNpiDetails'
import { useLocation } from 'react-router-dom'
import PayerSearch from './PayerSearch'
import CptSearch from './CptSearch'
import CdSearch from './CdSearch'
import CptTable from '../../components/table/CptTable'
import CdTable from '../../components/table/CdTable'
import RxInventory from './RxInventory'

const Profile = () => {


    const tabs =
        [
            {
                label: "User Profile", value: <EditViewProfile />,
                button: 'View Npi Details', changeTab: 1
            },
            {
                label: "Npi Information", value: <NpiInfo />, changeTab: 0, button: 'Back'
            },
            {
                label: "Sync NPI Details", value: <FindNpiDetails />, changeTab: 0, button: 'Back'
            },
            {
                label: "Payers Search", value: <PayerSearch />, changeTab: 0, button: 'Back'
            },
            {
                label: "Cpt Search", value: <CptTable />, changeTab: 0, button: 'Back'
            },
            {
                label: "ICD Codes", value: <CdTable />, changeTab: 0, button: 'Back'
            },
            {
                label: "RX Inventory", value: <RxInventory />, changeTab: 0, button: 'Back'
            }
        ]


    return (
        <section className='patients'>
            <Box sx={{
                boxShadow: '0 0 2px 0 #919EAB',
                borderRadius: '12px'
            }}>
                <MyTabs tabs={tabs} />
            </Box>
        </section>
    )
}

export default Profile
