import { CircularProgress, Grid } from '@mui/material'
import React from 'react'
import { sharedStyles } from '../../../../sharedStyles'
import GreenButton from '../../../claims/GreenButton'
import { colors } from '../../../../utils/colors'

const CommonNextButton = ({handleSubmit,noBack,setSelectedTab,nextButtonText, isLoading, onceCompleted}) => {
  //   used in pa forms + pharamcy / rtbc forms for eligibilty 
  return (
    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      sx={sharedStyles?.justifyEnd}
                      gap={1}
                  >
                   {!noBack && <GreenButton onClick={() =>  setSelectedTab(prev => prev -1)} >
                           Back
                      </GreenButton>}
                      <GreenButton 
                      disabled={isLoading}
                      onClick={()=>handleSubmit(false)} >
                        {isLoading ? <CircularProgress size={17} sx={{ color: colors?.white }}/> :    nextButtonText || 'Next'}
                      </GreenButton>
                      {onceCompleted && <GreenButton  onClick={()=>handleSubmit(true)} >
                           Done
                      </GreenButton>}
                  </Grid> 
  )
}

export default CommonNextButton