import { Box, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { styles } from '../../Aetna/styles'
import { sharedStyles } from '../../../../../sharedStyles'
import { UnicarePayload } from '../../../../../utils/staticData'
import toast from 'react-hot-toast'
import UnicareNextBtn from '../../UnicareNextBtn'
import { fixTheRegex } from '../../../../../utils/reusableFunctions'

const DefaultUnicareSubscriberInfo = ({subscriberInfo, setSubscriberInfo, setSelectedTab, onceCompleted}) => {
    const [showAllFields, setShowAllFields] = useState(false);
    const [err, setErr] = useState(false)
const {firstName: { pattern:fNamePattern, errorMessage:firstNameErrMessage  }, memberId: { pattern: memberIdPattern, errorMessage:memberIdErrMsg }, lastName: {pattern:lastnamePattern, errorMessage:lastNameErrMsg}

}= UnicarePayload?.subscriber?.elements;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSubscriberInfo((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    const handleSubmit = () => {
        const optionalFieldProvided = subscriberInfo?.firstName || subscriberInfo?.lastName;
        // Regex
        if(optionalFieldProvided) {
             if((!fixTheRegex(fNamePattern).test(subscriberInfo?.firstName) && subscriberInfo?.firstName) || (!fixTheRegex(lastnamePattern).test(subscriberInfo?.lastName) && subscriberInfo?.lastName)) {    
                return;
             }
        }
        if(!fixTheRegex(memberIdPattern).test(subscriberInfo?.memberId) && subscriberInfo?.memberId) {
            toast.error('Invalid Member Id')
            return;
        }   

        if(!subscriberInfo?.memberId){
            setErr(true)
            toast.error('Member ID is required')
            return;
        }
        setSelectedTab(prev => prev + 1);
    }

  return (
    <Paper elevation={3} sx={styles?.parentPaperContainer}>
    <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
        Subscriber Information
    </Typography>

      <RadioGroup
                value={showAllFields}
                onClick={(event) => setShowAllFields(!showAllFields)}
            >
                <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
            </RadioGroup>
        <Box sx={styles?.commonPaperContainer}>

            <Grid container spacing={3}>
                {showAllFields && 
                <>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        name='lastName'
                        label="Last Name"
                        value={subscriberInfo?.lastName}
                        onChange={handleChange}
                        error={subscriberInfo?.lastName && !fixTheRegex(lastnamePattern).test(subscriberInfo?.lastName)}
                        helperText={subscriberInfo?.lastName && !fixTheRegex(lastnamePattern).test(subscriberInfo?.lastName) ? lastNameErrMsg : ''}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="First Name"
                        name='firstName'
                        value={subscriberInfo?.firstName}
                        onChange={handleChange}
                        error={subscriberInfo?.firstName && !fixTheRegex(fNamePattern).test(subscriberInfo?.firstName)}
                        helperText={subscriberInfo?.firstName && !fixTheRegex(fNamePattern).test(subscriberInfo?.firstName) ? firstNameErrMessage : ''}
                    />
                </Grid>
                </>
                }

                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Member ID"
                        value={subscriberInfo?.memberId}
                        required
                        name='memberId'
                        onChange={handleChange}
                        helperText={
                            err && !subscriberInfo?.memberId
                              ? 'This field is required *'  
                              : !fixTheRegex(memberIdPattern).test(subscriberInfo?.memberId)
                              ? UnicarePayload?.subscriber?.elements?.memberId?.errorMessage || 'Invalid Member ID'
                              : ''  
                          }
                        error={!subscriberInfo?.memberId && err || (!fixTheRegex(memberIdPattern).test(subscriberInfo?.memberId) && subscriberInfo?.memberId)}
                        inputProps={{ maxLength: UnicarePayload?.subscriber?.elements?.memberId?.maxLength }}
                    />
                </Grid>

            </Grid>
        </Box>

        <UnicareNextBtn handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
</Paper>
  )
}

export default DefaultUnicareSubscriberInfo
