import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Box,
  CardHeader,
  CardContent,
  Avatar
} from '@mui/material';
import {
  Person,
  LocalPharmacy,
  Description,
  Info,
  MedicalServices
} from '@mui/icons-material';
import { numericDateFormat } from '../../utils/DateFunctions';
import { HeaderSection, InfoCard, InfoLabel, InfoValue, SectionTitle, StatusChip } from '../../components/RxInventoryDetailsPage/RxInventoryDetailsPageComponents';
import { colors } from '../../utils/colors';
import { getRxTransactionDetails } from '../../services/pVerify';
import { useParams } from 'react-router-dom';
import PageLoader from '../../components/loader/PageLoader'


const RxInventoryDetailsPage = () => {

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  // route states
  const { id } = useParams();
  const fetchRxDetails = async () => {
    try{
      setLoading(true)
      const details = await getRxTransactionDetails(id)
    setData(details?.data)
    }
    catch(e){
      console.log(e)
    }
    finally{
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchRxDetails()
  }, [])
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {loading?
    <PageLoader/>
    :
    <>
    <HeaderSection>
        <SectionTitle variant="h4" component="h1">
          Inventory Details
        </SectionTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InfoLabel>Drug Description</InfoLabel>
            <InfoValue variant="body1">{data?.inventory?.drugDescription}</InfoValue>
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoLabel>Dea Schedule</InfoLabel>
            <Box>
              <StatusChip
                label={data?.inventory?.deaSchedule}
                color="success"
                icon={<Info />}
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <InfoLabel>Product Information</InfoLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoLabel>Product Code</InfoLabel>
            <InfoValue variant="body1">{data?.inventory?.productCode}</InfoValue>
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoLabel>Product Qualifier</InfoLabel>
            <InfoValue variant="body1">{data?.inventory?.productCodeQualifier}</InfoValue>
          </Grid>
        </Grid>
      </HeaderSection>

      <Grid container spacing={3}>
        {/* Patient Information */}
        <Grid item xs={12} md={6}>
          <InfoCard>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: colors?.themeBlue }}>
                  <Person />
                </Avatar>
              }
              title="Patient Information"
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InfoLabel>Patient Name</InfoLabel>
                  <InfoValue variant="body1">
                    {`${data?.patient?.firstName} ${data?.patient?.lastName}`}
                  </InfoValue>
                </Grid>
                <Grid item xs={12}>
                  <InfoLabel>Date of Birth</InfoLabel>
                  <InfoValue variant="body1">
                    {numericDateFormat(data?.patient?.dateOfBirth)}
                  </InfoValue>
                </Grid>
                <Grid item xs={12}>
                  <InfoLabel>Patient ID</InfoLabel>
                  <InfoValue variant="body1">{data?.patient?.id}</InfoValue>
                </Grid>
              </Grid>
            </CardContent>
          </InfoCard>
        </Grid>

        {/* Prescriber Information */}
        <Grid item xs={12} md={6}>
          <InfoCard>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: colors?.themePink }}>
                  <MedicalServices />
                </Avatar>
              }
              title="Prescriber Information"
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InfoLabel>Prescriber NPI</InfoLabel>
                  <InfoValue variant="body1">{data?.prescriberNpi}</InfoValue>
                </Grid>
                <Grid item xs={12}>
                  <InfoLabel>Written Date</InfoLabel>
                  <InfoValue variant="body1">{numericDateFormat(data?.writtenDate)}</InfoValue>
                </Grid>
                <Grid item xs={12}>
                  <InfoLabel>Fill Date</InfoLabel>
                  <InfoValue variant="body1">{numericDateFormat(data?.fillDate)}</InfoValue>
                </Grid>
              </Grid>
            </CardContent>
          </InfoCard>
        </Grid>

        {/* Medication Information */}
        <Grid item xs={12}>
          <InfoCard>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: colors?.yellow }}>
                  <Description />
                </Avatar>
              }
              title="Medication Information"
            />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <InfoLabel>Medication</InfoLabel>
                  <InfoValue variant="body1">{data?.inventory?.drugDescription}</InfoValue>

                  <InfoLabel>Directions</InfoLabel>
                  <InfoValue variant="body1">{data?.directions}</InfoValue>

                  <InfoLabel>DEA Schedule</InfoLabel>
                  <InfoValue variant="body1">{data?.inventory?.deaSchedule}</InfoValue>
                </Grid>

                <Grid item xs={12} md={6}>
                  <InfoLabel>Product Code</InfoLabel>
                  <InfoValue variant="body1">
                    {data?.inventory?.productCode} ({data?.inventory?.productCodeQualifier})
                  </InfoValue>

                  <InfoLabel>Quantity & Days Supply</InfoLabel>
                  <InfoValue variant="body1">
                    {data?.quantity} units - {data?.daysSupply} days supply
                  </InfoValue>

                  <InfoLabel>Refills</InfoLabel>
                  <InfoValue variant="body1">
                    {data?.refills} ({data?.refillsQualifier === "REM" ? "Remaining" : data?.refillsQualifier})
                  </InfoValue>

                  <InfoLabel>Strength</InfoLabel>
                  <InfoValue variant="body1">
                    {data?.inventory?.strength || ''} 
                  </InfoValue>
                </Grid>
              </Grid>
            </CardContent>
          </InfoCard>
        </Grid>

        {/* Pharmacy Information */}
        <Grid item xs={12}>
          <InfoCard>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: colors?.green }}>
                  <LocalPharmacy />
                </Avatar>
              }
              title="Pharmacy Information"
            />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <InfoLabel>Pharmacy Name</InfoLabel>
                  <InfoValue variant="body1">{data?.rxPharmacy?.pharmacyName}</InfoValue>

                  <InfoLabel>Address</InfoLabel>
                  <InfoValue variant="body1">
                    {data?.rxPharmacy?.pharmacyAddressLine1}
                    {data?.rxPharmacy?.pharmacyAddressLine2 && <>, {data?.rxPharmacy?.pharmacyAddressLine2}</>}<br />
                    {data?.rxPharmacy?.pharmacyCity}, {data?.rxPharmacy?.pharmacyState} {data?.rxPharmacy?.pharmacyZipCode}
                  </InfoValue>
                </Grid>

                <Grid item xs={12} md={6}>
                  <InfoLabel>NCPDP ID</InfoLabel>
                  <InfoValue variant="body1">{data?.rxPharmacy?.pharmacyNcpdpid}</InfoValue>

                  <InfoLabel>NPI</InfoLabel>
                  <InfoValue variant="body1">{data?.rxPharmacy?.pharmacyNpi}</InfoValue>

                  <InfoLabel>DEA Number</InfoLabel>
                  <InfoValue variant="body1">{data?.rxPharmacy?.pharmacyDeaNumber}</InfoValue>
                </Grid>
              </Grid>
            </CardContent>
          </InfoCard>
        </Grid>
      </Grid>
    </>  
    }
    </Container>
  );
};

export default RxInventoryDetailsPage;
