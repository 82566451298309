import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { sharedStyles } from '../../sharedStyles'

const AdvancedQuickSearch = ({value,onChange,labels}) => {
    return (
        <RadioGroup
            sx={sharedStyles?.horizonatalItems}
            value={value}
            onChange={onChange}
        >
            <FormControlLabel
                value={false}
                control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />}
                label={labels?.quick || "Quick Search"}
            />
            <FormControlLabel
                value={true}
                control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />}
                label={labels?.advanced || "Advanced Search"}
            />
        </RadioGroup>
    )
}

export default AdvancedQuickSearch