import React, { useContext } from 'react'
import { MercyGroupContext } from '../../../../../context/priorAuthorizationForms/MercyCareContext';
import DefaultProviderNotes from '../../components/MercyCare/DefaultProviderNotes';

const MercyCareProviderNotes = () => {
    const {providerNotes, setProviderNotes, setSelectedTab} = useContext(MercyGroupContext);
    
  return (
    <DefaultProviderNotes
    providerNotes={providerNotes}
    setProviderNotes={setProviderNotes}
    setSelectedTab={setSelectedTab}
    />
  )
}

export default MercyCareProviderNotes