import { exportDiscoveryData} from '../../services/registration';

export const exportDiscoveryDataToExcel = async (startDate, endDate) => {
  try {
    const responseData = await exportDiscoveryData(startDate, endDate);
    if(responseData){
    const url = window.URL.createObjectURL(new Blob([responseData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Discovery_Export_${startDate.format('YYYY-MM-DD')}_to_${endDate.format('YYYY-MM-DD')}.xlsx`);
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    }
  } catch (error) {
    console.error('Error in export process:', error);
  }
};