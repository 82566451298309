import React, { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async';
import { get_payersdata, getDistinctPayers } from '../services/PayerService';
import { colors } from '../utils/colors';

const PayersDropdown = ({ value, handleChange, isMulti, setter,placeholder,error }) => {
    const [payers, setPayers] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    
    const fetchPayers = async (page, size) => {
        try {
            const response = await getDistinctPayers(page, size);
            return response?.data.map((payer) => ({
                label: payer.payerName,
                value: payer.payerName
            }))
        } catch (error) {
            console.log(error)
        }
    }

    const loadOptions = async (inputValue, callback) => {
        setLoading(true);
        const newPayers = await fetchPayers(page, 10);
        setPayers(prevPayers => [...prevPayers, ...newPayers]);
        setPage(prevPage => prevPage + 1);
        setLoading(false);
        callback(newPayers);
    };

    const filterPayersBySearch = (inputValue) => {
        if (inputValue) {
            const filteredPayers = payers.filter(payer => payer.label.toLowerCase().includes(inputValue.toLowerCase()));
            return filteredPayers;
        }
        return payers;
    }

    const handleScroll = async (e) => {
        const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;

        if (bottom && !loading) {
            setLoading(true);
            const newPayers = await fetchPayers(page, 10);
            setPayers(prevPayers => [...prevPayers, ...newPayers]);
            setPage(prevPage => prevPage + 1);
            setLoading(false);
        }
    };

    useEffect(() => {
        const loadInitialPayers = async () => {
            setLoading(true);
            const initialPayers = await fetchPayers(page, 10);
            setPayers(initialPayers);
            setLoading(false);
        };

        loadInitialPayers();
    }, []);
  
    return (
        <div>
            <AsyncSelect
                cacheOptions
                loadOptions={(inputValue, callback) => {
                  if(inputValue){
                      callback(filterPayersBySearch(inputValue))
                  } else {
                        loadOptions(inputValue, callback)
                  }
                }}
                defaultOptions={payers}
                isLoading={loading}
                placeholder={placeholder || "Insurance Name"}
                onMenuScrollToBottom={handleScroll}
                onChange={handleChange}
                defaultValue={[]}
                isMulti={true}
                value={value}
                isSearchable={true}
                styles={{
                    menu: (provided, state) => ({
                        ...provided,
                        zIndex: 9999,
                        
                    }),
                    control: (provided) => ({
                        ...provided,
                        padding: '9px',
                        border:  `1px solid ${error ? colors?.red : provided?.borderColor}`,
                    }),
                    placeholder: (provided) => ({
                          ...provided,
                          color: error ? colors?.errorRed : provided.color
                        }),
                }}
            />
        </div>
    )
}

export default PayersDropdown