import React, { useContext, useState } from 'react';
import {
    Box,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';

import { UnicareContext } from '../../../../../context/priorAuthorizationForms/UnicareArContext';
import { sharedStyles } from '../../../../../sharedStyles';
import { styles } from '../../Aetna/styles';
import UnicareNextBtn from '../../UnicareNextBtn';

const DefaultDatesInfo = () => {
    const { setSelectedTab, datesInfo: formData, setDatesInfo: setFormData } = useContext(UnicareContext);
    const [showAllFields, setShowAllFields] = useState(false);
    const [errors, setErrors] = useState({ fromDate: false })

    const handleDateChange = (date, field) => {
        setFormData({
            ...formData,
            [field]: date
        });
    };


    const handleSubmit = () => {
        if (!formData?.fromDate) {
            toast.error('please enter required from date to continue')
            setErrors((prev)=>({...prev,fromDate:true}))
            return
        }
        else {
            setErrors((prev) => ({ ...prev, fromDate: false }))
            setSelectedTab((prev) => prev + 1)
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Dates Information
                </Typography>
                <RadioGroup
                    value={showAllFields}
                    onClick={(event) => setShowAllFields(!showAllFields)}
                >
                    <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                </RadioGroup>
                <Box sx={styles?.commonPaperContainer}>
                    <Grid container spacing={3} mb={2}>


                        {/* From Date */}
                        <Grid item xs={12} md={showAllFields ? 6 : 12}>
                            <DatePicker
                                label="From Date"
                                sx={errors?.fromDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                value={formData.fromDate}
                                onChange={(date) => handleDateChange(date, 'fromDate')}
                                minDate={dayjs('2002-01-01')}
                                maxDate={dayjs('2026-06-14')}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        required: true,
                                    }
                                }}
                            />
                        </Grid>

                        {/* To Date */}
                        {showAllFields &&
                            <Grid item xs={12} md={6}>
                                <DatePicker
                                    label="To Date"
                                    value={formData.toDate}
                                    onChange={(date) => handleDateChange(date, 'toDate')}
                                    minDate={dayjs('2002-01-01')}
                                    maxDate={dayjs('2026-06-14')}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                        }
                                    }}
                                />
                            </Grid>
                        }



                    </Grid>
                </Box>
                <UnicareNextBtn handleSubmit={handleSubmit} setSelectedTab={setSelectedTab}/>

            </Paper>

        </LocalizationProvider>
    );
};

export default DefaultDatesInfo;