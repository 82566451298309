import { API_BASE_URL } from "../utils/staticData";
import axios from "axios";
import { userGetAxios } from "./userApis";

export const get_Vendors = async () => {
    const url = `${API_BASE_URL}/api/Vendor/GetAllVendors`;

    try {
        const response = await userGetAxios(url)
        return response;
    } catch (error) {
        console.log('Error fetching data:', error);

    }
};