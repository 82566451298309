import { colors } from "../../utils/colors";

export const styles = {
    commonPaperContainer:{ 
        mb: 4, 
        p: 2, 
        border: `1px solid ${colors?.lightGrayBorder}`, 
        borderRadius: 1 
    },
    parentPaperContainer:{ 
        p: 4, 
        width: '80%', 
        mx: 'auto', 
        my: 4
     },
     themeStyle:{
        palette: {
          primary: {
            main: colors?.muiThemeBlue,
          },
          secondary: {
            main: colors?.themePink,
          },
          background: {
            default: colors?.tableGray,
          },
        },
        typography: {
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          h4: {
            fontWeight: 600,
          },
          h5: {
            fontWeight: 500,
          },
          h6: {
            fontWeight: 500,
          },
        },
        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: 12,
                boxShadow: `0 4px 12px 0 ${colors?.defaultBoxShadow}`,
              },
            },
          },
          MuiCardHeader: {
            styleOverrides: {
              root: {
                padding: '16px 24px',
              },
            },
          },
          MuiCardContent: {
            styleOverrides: {
              root: {
                padding: '24px',
              },
            },
          },
        },
      },
      containerBox:{ backgroundColor: colors?.tableGray, minHeight: '100vh', py: 4 },
      mainHeading:{ mb: 4, color: colors?.muiThemeBlue },
      cardHeader:{ borderBottom: `1px solid ${colors?.surfaceLighter}`, backgroundColor: colors?.snowwhite },
      patientChip:{ ml: 2, backgroundColor: colors?.bluebor, color: colors?.white },
      patientListItem:{ px: 0, py: 1 },
      patientIcons:{ mr: 2, color: colors?.muiThemeBlue },
      medicationPaper:{ p: 2, backgroundColor: colors?.snowwhite, borderRadius: 2 , height:'100%'},
      pharmacyInfoChip:{ display: 'flex', flexWrap: 'wrap', gap: 1 },
      responseCard:{ display: 'flex', alignItems: 'center' }
}