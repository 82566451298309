import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Autocomplete, Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { styles } from './styles'
import { sharedStyles } from '../../sharedStyles'
import TableHeader from '../../components/TableHeader'
import PageLoader from '../../components/loader/PageLoader'
import AdvancedQuickSearch from '../../components/RadioButtons/AdvancedQuickSearch'
import CenteredSubmitCancel from '../../components/buttons/CenteredSubmitCancel'
import { get_payersdata, getDistinctPayers } from '../../services/PayerService'
import PaginatedSearchSelect from '../../components/SearchFields/PaginatedSearchSelect'
import { colors } from '../../utils/colors'
import { claimsNewSearchLoaders, claimsVendorOptions, claimNewSearchDefaultData, paFormErrors, aetnaDefaultData } from '../../utils/staticData'
import toast from 'react-hot-toast'
import { get_GetPatientDataById } from '../../services/addPatient'
import { dayJsMdyFormat, dayJsMdyFormatSlash } from '../../utils/DateFunctions'
import { searcClaimsForPatient } from '../../services/ClaimService'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { get_Vendors } from '../../services/vendorSerice'

const ClaimsNewSearch = () => {
    const [patientSelected, setPatientSelected] = useState(null)
    const [data, setData] = useState({
        ...claimNewSearchDefaultData,
        npi: JSON.parse(localStorage?.getItem('selectedNpi'))?.npiNumber?.toString() || '',
    })
    const [loaders, setLoaders] = useState(claimsNewSearchLoaders)
    const [showAllFields, setShowAllFields] = useState(false)
    const [errors, setErrors] = useState({})
    const [vendors, setVendors] = useState([]) 

    // states for insurance field
    const [options, setOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    // route states
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const allParams = Object.fromEntries(searchParams.entries());
    const { advanced } = allParams;

    // functions
    const handleDateChange = (date, field) => {
        setData({
            ...data,
            [field]: date,
        });
    };
    const handleSubmit = async () => {
        const { firstName, lastName, npi, payers, vendors, scheduleDate, ProviderFederalTaxId, serviceStartDate, serviceEndDate, dob, memberId, gender } = data
        if (!firstName || !lastName 
            || !npi || !payers|| !vendors || !scheduleDate || !serviceEndDate || !serviceStartDate) {
            setErrors({
                firstName: firstName ? false : true,
                lastName: lastName ? false : true,
                npi: npi ? false : true,
                payers: payers ? false : true,
                vendors: vendors ? false : true,
                scheduleDate: scheduleDate ? false : true,
                serviceStartDate: serviceStartDate ? false : true,
                serviceEndDate: serviceEndDate  ? false : true
            })
            toast.error(paFormErrors?.propInfo)
            return
        }
        else {
            try {

                setErrors({})
                setLoaders(prev => ({ ...prev, submission: true }))
                const payload = {
                    transactionDate: dayJsMdyFormatSlash(scheduleDate),
                    payerCode: payers?.label,
                    ProviderLastName: '',
                    ProviderNPI: npi?.toString(),
                    ProviderFederalTaxId,
                    serviceStartDate: dayJsMdyFormatSlash(serviceStartDate),
                    serviceEndDate: dayJsMdyFormatSlash(serviceEndDate),
                    subscriber: {
                        FirstName: firstName,
                        lastName,
                        dob: dayJsMdyFormatSlash(dob),
                        memberID: memberId,
                        gender: gender
                    },
                    isPatientDependent: false,
                    vendor: {
                        vendor_name: vendors?.value
                    }
                }
                const submittedData = await searcClaimsForPatient(payload)
                submittedData?.data?.patientId && navigate(`/claimsSearchResult/${submittedData?.data?.patientId }`)
            }
            catch (e) {
                console.log(e)
            }
            finally {
                setLoaders(prev => ({ ...prev, submission: false }))
            }
        }

    }

    const getPayersList = async () => {
        try {
            setLoaders((prev) => ({ ...prev, payers: true }))
            let allOptions = []
            const allPayers = await getDistinctPayers(page, 7, null, searchTerm)
            const newOptions = allPayers?.data?.map((item) => ({
                value: item.payerName,
                label: item.payerName,
            }));

            if (page === 1) {
                setOptions(newOptions);
            } else {
                allOptions = [...options, ...newOptions]

                setOptions(allOptions);
            }

            setHasMore(allOptions?.length < allPayers?.totalCount);
        }
        catch (e) {
            console.log(e)
        }
        finally {
            setLoaders((prev) => ({ ...prev, payers: false }))
        }
    }


    const handleSearch = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setPage(1);
    };

    const handleSelectedPatient = async () => {
        try {
            setLoaders(prev => ({ ...prev, patient: true }))
            const selectedPatientData = await get_GetPatientDataById(patientSelected?.id)
            if (selectedPatientData?.data) {
                const { firstName, lastName, dob, middleName, memberId, patientInsuranceDetails } = selectedPatientData?.data
                setData(prev => {
                    return ({
                        ...prev,
                        firstName,
                        lastName,
                        dob: dayjs(dob),
                        middleName,
                        memberId,
                        payers: patientInsuranceDetails?.[0]?.length && patientInsuranceDetails?.[0]?.split(',')?.map(e => ({ value: e, label: e }))
                    })
                })
            }
        }
        catch (e) {
            console.log(e)
        }
        finally {
            setLoaders(prev => ({ ...prev, patient: false }))
        }
    }

    const getAllVendors = async() => {
       try{
        setLoaders(prev => ({...prev,vendors:true}))
        const allVendors = await get_Vendors()
        setVendors(allVendors?.vendors?.map(e => {
            return(
                {
                    value:e?.vendorName,
                    label:e?.vendorName
                }
            )
        }))
       }
       catch(e){
        console.log('error fetching vendors for claims',e)
       }
       finally{
        setLoaders(prev => ({...prev,vendors:false}))
       }
    }

    // useEffects
    useEffect(() => {
        getPayersList()
    }, [searchTerm, page])

    useEffect(() => {
        if (patientSelected?.id) {
            handleSelectedPatient()
        }
    }, [patientSelected])

    useEffect(()=>{
        if(advanced){
            setShowAllFields(true)
        }
        getAllVendors()
    },[])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Search Claims for Patient
                </Typography>
                <div style={sharedStyles?.mt10px}>
                    <TableHeader
                        color={'success'}
                        setFilterPatient={setPatientSelected} idNeeded
                        handleCrossValue={() => setData((prev) => ({
                            ...prev,
                            firstName: '',
                            lastName: '',
                            middleName: '',
                            dob: null,
                            payers: [],
                            memberId: ''
                        }))}
                    />
                </div>

                {loaders?.patient ? (
                    <PageLoader />
                ) : (
                    <>
                        <AdvancedQuickSearch
                            value={showAllFields}
                            onChange={(e) => setShowAllFields(e.target.value === "true")}
                        />


                        <Box sx={styles?.commonPaperContainer}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={'First Name'}
                                        value={data?.firstName}
                                        onChange={(e) => { setData(prev => ({ ...prev, firstName: e?.target?.value })) }}
                                        required
                                        error={errors?.firstName}
                                        color="success"

                                    />
                                </Grid>
                                {showAllFields && <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={'Middle Name'}
                                        value={data?.middleName}
                                        onChange={(e) => { setData(prev => ({ ...prev, middleName: e?.target?.value })) }}
                                        color="success"


                                    />
                                </Grid>}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={'Last Name'}
                                        value={data?.lastName}
                                        onChange={(e) => { setData(prev => ({ ...prev, lastName: e?.target?.value })) }}
                                        required
                                        error={errors?.lastName}
                                        color="success"

                                    />
                                </Grid>
                                {showAllFields &&
                                    <>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth
                                            error={!!errors?.gender}
                                        >
                                            <InputLabel color='success'>Patient Gender</InputLabel>
                                            <Select
                                                color='success'
                                                value={data.gender}
                                                label="Patient Gender"
                                                onChange={(e) => setData(prev => ({ ...prev, gender: e?.target?.value }))}

                                            >
                                                {aetnaDefaultData?.patientGenders?.map(({ value, label }) => (
                                                    <MenuItem key={value} value={value}>
                                                        {label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                      <Grid item xs={12} md={6}>
                                      <DatePicker
                                          label={'Date of Birth'}
                                          sx={errors?.dob ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultGreenDatePickerStyles}
                                          value={data?.dob}
                                          onChange={(date) => handleDateChange(date, 'dob')}
                                          minDate={dayjs()}
                                          slotProps={{
                                              textField: {
                                                  fullWidth: true,
                                                  error: !!errors?.dob,
                                              },
                                          }}
                                      />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                      <TextField
                                          fullWidth
                                          label={'Member ID'}
                                          value={data?.memberId}
                                          onChange={(e) => { setData(prev => ({ ...prev, memberId: e?.target?.value })) }}
                                          error={errors?.memberId}
                                          color="success"

                                      />
                                  </Grid>
                                    </>
                                }
                                <Grid item xs={12} md={6}>
                                    <PaginatedSearchSelect
                                        options={options}
                                        onSearch={handleSearch}
                                        onLoadMore={() => setPage((prevPage) => prevPage + 1)}
                                        isLoading={loaders?.payers}
                                        hasMore={hasMore}
                                        placeholder="Insurance Name *"
                                        // isMulti
                                        onChange={(selectedOption) => setData(prev => ({ ...prev, payers: selectedOption }))}
                                        value={data?.payers}
                                        error={errors?.payers}
                                    />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <PaginatedSearchSelect
                                        options={vendors}
                                        placeholder="Select Vendors *"
                                        onChange={(selectedOption) => setData(prev => ({ ...prev, vendors: selectedOption }))}
                                        value={data?.vendors}
                                        error={errors?.vendors}
                                        isLoading={loaders?.vendors}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                            <DatePicker
                                                label={'Service Start Date'}
                                                sx={errors?.serviceStartDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultGreenDatePickerStyles}
                                                value={data?.serviceStartDate}
                                                onChange={(date) => handleDateChange(date, 'serviceStartDate')}
                                                // minDate={dayjs()}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: !!errors?.serviceStartDate,
                                                        required:true
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <DatePicker
                                                label={'Service End Date'}
                                                sx={errors?.serviceEndDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultGreenDatePickerStyles}
                                                value={data?.serviceEndDate}
                                                onChange={(date) => handleDateChange(date, 'serviceEndDate')}
                                                // minDate={dayjs()}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: !!errors?.serviceEndDate,
                                                        required:true
                                                    },
                                                }}
                                            />
                                        </Grid>
                                <Grid item xs={12} md={showAllFields ? 6 : 12}>
                                    <DatePicker
                                        label={'Schedule Date'}
                                        sx={errors?.scheduleDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultGreenDatePickerStyles}
                                        value={data?.scheduleDate}
                                        onChange={(date) => handleDateChange(date, 'scheduleDate')}
                                        minDate={dayjs()}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                required: true,
                                                error: !!errors?.scheduleDate,
                                            },
                                        }}
                                    />
                                </Grid>
                                {showAllFields &&
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                label={'NPI'}
                                                value={data?.npi}
                                                onChange={(e) => { setData(prev => ({ ...prev, npi: e?.target?.value })) }}
                                                error={errors?.npi}
                                                inputProps={{
                                                    maxLength: 10
                                                }}
                                                helperText='Enter a valid Requesting Provider National Provider Identifier (NPI) containing 10 numeric digits'
                                                color="success"

                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                label={'Provider Federal Tax ID'}
                                                value={data?.ProviderFederalTaxId}
                                                onChange={(e) => { setData(prev => ({ ...prev, ProviderFederalTaxId: e?.target?.value })) }}
                                                 helperText='Enter a valid  Federal Tax ID containing 9 numeric digits'
                                                inputProps={{
                                                    maxLength: 9
                                                }}
                                                color="success"

                                            />
                                        </Grid>
                                        
                                    </>

                                }
                            </Grid>
                            <CenteredSubmitCancel
                                hideCancel
                                mt={1}
                                onClick={handleSubmit}
                                disabled={loaders?.submission}
                                loading={loaders?.submission}
                            />
                        </Box>
                    </>
                )}
            </Paper>
        </LocalizationProvider>
    )
}

export default ClaimsNewSearch