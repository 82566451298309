import { Box, Card, CardContent, CardHeader, Chip, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { styles } from './styles'
import { sharedStyles } from '../../sharedStyles'
import HorizontalBox from '../../components/shared/HorizontalBox'

const RxBenefitsResponseDetails = ({ response }) => {
    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader
                    title="Response Information"
                    sx={styles?.cardHeader}
                />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} >
                            <Paper elevation={0} sx={styles?.medicationPaper}>
                                <Typography variant="subtitle2" color="text.secondary">Reason Code:</Typography>
                                <Typography variant="body1">{response?.ReasonCode}</Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <Paper elevation={0} sx={styles?.medicationPaper}>
                                <HorizontalBox>
                                    <Typography variant="subtitle2" color="text.secondary" sx={sharedStyles?.mr2}>Status:</Typography>
                                    <Chip
                                        label={response?.ProcessedStatus ? "Processed" : "Not Processed"}
                                        color={response?.ProcessedStatus ? "success" : "error"}
                                    />
                                </HorizontalBox>
                            </Paper>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default RxBenefitsResponseDetails