import { colors } from '../../utils/colors';
export const styles = {
    graphContainer: {
        justifyContent: 'space-between', mb: 2
    },
    cardValue: {
        fontWeight: 'bold'
    },
    cardText: { marginY: 2 },
    filterButton:{color:colors?.themeGreen, border: `1px solid ${colors?.themeGreen}`},
    chartParentContainer:{
      width: '100%',
      height: '500px',
      padding: '2px', 
    },
    chartContainer: {
      boxShadow: `0px 3px 6px ${colors?.shadow}`, 
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      borderRadius: '4px', 
      padding: '8px',
      flexDirection: 'column',
    },
    importedPatientModal:{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      height: '90vh',
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: 1,
      p: 2,
      pt:4
    },
    importedPatientCrossIcon:{
      position: 'absolute',
      right: 8,
      top: 8,
      color: 'grey.500'
    },
    importedPatientTable:{ 
      height: '90%',
      mt: 2
    },
    tableHeadCell:{
      backgroundColor:colors?.tableHeadBg,
      color: colors?.white,
      fontWeight: 'bold'
    },
    tableToolTip:{
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '200px' 
    },
    link: { color: colors?.white, textDecoration: 'none' }
}

export const addPatientStyles = {
  parentPaperContainer:{ 
    p: 4, 
    width: '80%', 
    mx: 'auto', 
    my: 4
 }
}

export const appointmentCalendarStyles = {
  calendarContainer: {
    height: '500px'
  },
  calendar: {
    height: '100%'
  },
  event: {
    backgroundColor: colors.themeGreen,
    color: colors.white,
    border: 'none'
  }
};

export const createEventModalStyles = {
 modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: 400,
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    my: 2,
  },
  formField: {
    mb: 2,
    width: '100%',
  },
  notesField: {
    mb: 3,
    width: '100%',
  },
  createButton: {
    width: '100%',
    backgroundColor: colors.themeGreen,
    '&:hover': {
      backgroundColor: colors.lightGreen,
    },
  },
  buttonGroup: {
    mb: 2,
    width: '100%',
  },
  durationButton: {
    backgroundColor: 'transparent',
    color: colors.themeGreen,
    borderColor: colors.themeGreen,
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.themeGreen,
    },
    '&.Mui-selected': {
      backgroundColor: colors.themeGreen,
      color: colors.white,
      '&:hover': {
        backgroundColor: colors.themeGreen,
        opacity: 0.9,
      },
    },
  },
  customDurationBox: {
    display: 'flex',
    gap: 2,
    mb: 2,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  durationField: {
    width: '80px',
    '& .MuiInputLabel-root': {
      fontSize: '0.8rem',
    },
    '& .MuiInputBase-root': {
      height: '40px',
    },
  },
  durationInput: {
    py: 0.5,
    px: 1,
    '& input': {
      textAlign: 'center',
    },
  },
  applyButton: {
    height: '40px',
    minWidth: '80px',
    backgroundColor: colors.themeGreen,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.themeGreen,
      opacity: 0.9,
    },
  },
};

export const editEventModalStyles = {
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: 400,
    maxHeight: '90vh',
    overflowY: 'auto',
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    my: 2,
  },
  formField: {
    mb: 2,
    width: '100%',
  },
  notesField: {
    mb: 3,
    width: '100%',
  },
  updateButton: {
    width: '100%',
    backgroundColor: colors.themeGreen,
    '&:hover': {
      backgroundColor: colors.lightGreen, 
    },
  },
  buttonGroup: {
    mb: 2,
    width: '100%',
  },
  durationButton: {
    backgroundColor: 'transparent',
    color: colors.themeGreen,
    borderColor: colors.themeGreen,
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.themeGreen,
    },
    '&.Mui-selected': {
      backgroundColor: colors.themeGreen,
      color: colors.white,
      '&:hover': {
        backgroundColor: colors.themeGreen,
        opacity: 0.9,
      },
    },
  },
  customDurationBox: {
    display: 'flex', 
    gap: 2, 
    mb: 2, 
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  durationField: {
    width: '80px',
    '& .MuiInputLabel-root': {
      fontSize: '0.8rem',
    },
    '& .MuiInputBase-root': {
      height: '40px',
    },
  },
  durationInput: {
    py: 0.5,
    px: 1,
    '& input': {
      textAlign: 'center',
    },
  },
  applyButton: {
    height: '40px',
    minWidth: '80px',
    backgroundColor: colors.themeGreen,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.themeGreen,
      opacity: 0.9,
    },
  },
};

export const eventDetailsModalStyles = {
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: colors.white,
        boxShadow: 24,
        p: 4,
        width: 400,
        maxHeight: '90vh',
        overflowY: 'auto',
        borderRadius: 2,
      },
      headerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
      },
      modalTitle: {
        variant: 'h6',
        component: 'h2',
      },
      detailsBox: {
        marginBottom: 2,
      },
      buttonBox: {
        marginTop: 2,
        display: 'flex',
        justifyContent: 'space-between',
      },
      button: {
        variant: 'contained',
      },
      editButton: {
        backgroundColor: colors.themeGreen, 
        color: colors.white,
        '&:hover': {
            backgroundColor: colors.lightGreen, 
        },
      },
      deleteButton: {
        backgroundColor: colors.lightRed,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.danger,
        },
      },
   
};