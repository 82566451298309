import React, { useContext, useState } from 'react'
import {  floridaBlueArServiceTypes } from '../../../../../utils/staticData'
import ImpServicesType from '../../components/Bcbs/impServiceType'
import { FloridaBlueArContext } from '../../../../../context/priorAuthorizationForms/FloridaBlueArContext'

const ServicesTypeFloridaBlueArForm = () => {
    const {setSelectedTab,serviceInfo: serviceType,setServiceInfo: setServiceType, onceCompleted,setOnceCompleted} = useContext(FloridaBlueArContext)
     const [errors, setErrors] = useState({serviceType:false})
  return (
   <ImpServicesType
   errors={errors}
   setErrors={setErrors}
   setSelectedTab={setSelectedTab}
   serviceType={serviceType}
   setServiceType={setServiceType}
   onceCompleted={onceCompleted}
   serviceTypes={floridaBlueArServiceTypes}
   noBack
   onComplete={() => onceCompleted && setOnceCompleted(false)}
   
  
   />
  )
}

export default ServicesTypeFloridaBlueArForm