import React, { useContext } from 'react'
import DefaultUnicareProviderNotes from '../../components/Unicare/DefaultUnicareProviderNotes'
import { UnicareContext } from '../../../../../context/priorAuthorizationForms/UnicareArContext';

const UnicareArProviderNotes = () => {
    const { providerNotes, setProviderNotes, setSelectedTab, onceCompleted } = useContext(UnicareContext);

    return (
        <DefaultUnicareProviderNotes
            onceCompleted={onceCompleted}
            providerNotes={providerNotes}
            setProviderNotes={setProviderNotes}
            setSelectedTab={setSelectedTab}
        />
    )
}

export default UnicareArProviderNotes
