import React, { useContext } from 'react'
import { MercyGroupContext } from '../../../../../context/priorAuthorizationForms/MercyCareContext';
import DefaultPatientInfo from '../../components/MercyCare/DefaultPatientInfo';

const MeryCarePatientInfo = () => {
  const {setSelectedTab, patientInfo, setPatientInfo} = useContext(MercyGroupContext);

  return (
    <DefaultPatientInfo 
    patientInfo={patientInfo}
    setPatientInfo={setPatientInfo}
    setSelectedTab={setSelectedTab}
    />
  )
}

export default MeryCarePatientInfo