import { Box, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import React, { useEffect, useState } from 'react'
import { styles } from '../../Aetna/styles'
import { sharedStyles } from '../../../../../sharedStyles'
import TableHeader from '../../../../TableHeader'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PageLoader from '../../../../loader/PageLoader'
import { UnicarePayload } from '../../../../../utils/staticData'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import UnicareNextBtn from '../../UnicareNextBtn'
import { getPatientDetails } from '../commonFunctions'
import { fixTheRegex } from '../../../../../utils/reusableFunctions'

const DefaultUnicarePatientInfo = ({ patientInfo, setPatientInfo, setSelectedTab }) => {
    const [patientSelected, setPatientSelected] = useState(null);
    const [showAllFields, setShowAllFields] = useState("Required");
    const [loader, setLoader] = useState(false);
    const [err, setErr] = useState(false)

    const {
        firstName: { label: firstNameLabel, errorMessage: fNameErrMsg, required:firstNameRequired, pattern:firstNamePattern},
        lastName: { errorMessage: lastNameErrMsg, label: lastNameLabel,pattern:lastnamePattern },
        birthDate: { required: dobRequired, errorMessage: dobErrMsg, label: dobLabel },
        genderCode: { required: genderRequired, label: genderLabel, values: genderValues },
        statusCode: { required: statusCodeRequired, label: statusCodeLabel, values: statusCodeValues },
        subscriberRelationshipCode: { values: subscriberRelationshipCodeValues, label: subscriberRelationshipCodeLabel, },
    } = UnicarePayload.patientInfo.elements;


    const handleChange = (e) => {
        const { name, value } = e.target;
      
        setPatientInfo({ ...patientInfo, [name]: value });
    }

    

    const handleDateChange = (date) =>  {
        setPatientInfo({ ...patientInfo, birthDate: date });
    }

    const handleSubmit = () => {
        const { birthDate, lastName, firstName } = patientInfo;
        const optionalFieldProvided = lastName || firstName;
        if (!birthDate) {
            toast.error('Please enter all required information to continue.');
            setErr(true)
        } else if(optionalFieldProvided) {
                const regex1 = fixTheRegex(lastnamePattern);
                const regex2 = fixTheRegex(firstNamePattern);
                if(!regex1.test(patientInfo?.lastName) && patientInfo?.lastName){
                    setErr(true)
                    return;
                } else if(!regex2.test(patientInfo?.firstName) && patientInfo?.firstName) {
                    setErr(true)

                    return;
                }
                setSelectedTab((prev) => prev + 1);
        }
        else {
            setSelectedTab((prev) => prev + 1);
        }
    };

    useEffect(() => {
        patientSelected?.id && getPatientDetails(patientSelected, setLoader, setPatientInfo, patientInfo)
    }, [patientSelected])




    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Patient Information
                </Typography>

                <div style={sharedStyles?.mt10px}>
                    <TableHeader setFilterPatient={setPatientSelected} idNeeded handleCrossValue={() => setPatientInfo({})} />
                </div>

                {
                    loader ?
                        <PageLoader />
                        :
                        <>
                            <FormControl>
                                <FormLabel id='show-required-fields'>Form Type</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='show-required-fields'
                                    defaultValue={showAllFields}
                                    value={showAllFields}
                                    onClick={(e) => setShowAllFields(e.target.value)}
                                >
                                    <FormControlLabel value={"Required"} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Quick Search" />
                                    <FormControlLabel value={"All Fields"} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Advance" />
                                </RadioGroup>

                            </FormControl>

                            <Box sx={styles?.commonPaperContainer}>
                                <Grid container spacing={3}>
                                {
                                    showAllFields == "All Fields" &&
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label={firstNameLabel}
                                                value={patientInfo?.firstName}
                                                onChange={handleChange}
                                                name='firstName'
                                                inputProps={{
                                                    maxLength: 60,
                        
                                                }}
                                                required
                                                error={err && patientInfo?.firstName  && !fixTheRegex(firstNamePattern).test(patientInfo?.firstName)}
                                                helperText={err && patientInfo?.firstName && !fixTheRegex(firstNamePattern).test(patientInfo?.firstName) ? fNameErrMsg : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label={lastNameLabel}
                                                name='lastName'
                                                value={patientInfo?.lastName}
                                                onChange={handleChange}
                                                inputProps={{
                                                    maxLength: 60,
                                                }}
                                                required
                                                error={err && patientInfo?.lastName && !fixTheRegex(lastnamePattern).test(patientInfo?.lastName)}
                                                helperText={err && patientInfo?.lastName && !fixTheRegex(lastnamePattern).test(patientInfo?.lastName) ? lastNameErrMsg : ''}
                                            />
                                        </Grid>
                                    </>
                                }


                                    <Grid item xs={12} md={6}>
                                        <DatePicker
                                            label={dobLabel}
                                            name='birthDate'
                                            sx={err && !patientInfo?.birthDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                            value={patientInfo?.birthDate || null}
                                            minDate={dayjs('1870-01-01')}
                                            maxDate={dayjs('2024-12-12')}
                                            onChange={handleDateChange}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    required: dobRequired,
                                                    helperText: !patientInfo?.birthDate && err ? dobErrMsg : "Required",
                                                    error: !patientInfo?.birthDate && err,
                                                    inputProps: { readOnly: true },
                                                }
                                            }}
                                        />
                                    </Grid>
                                    {
                                        showAllFields == "All Fields" &&
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>{genderLabel}</InputLabel>
                                                <Select
                                                    name='genderCode'
                                                    required={genderRequired}
                                                    value={patientInfo?.genderCode}
                                                    label={genderLabel}
                                                    onChange={handleChange}
                                                // onChange={handleChange('genderCode')}
                                                >
                                                    {genderValues?.map(({ value, code }) => {
                                                        return (
                                                            <MenuItem value={code}>{value}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }

                                    {
                                        showAllFields == "All Fields" &&
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>{statusCodeLabel}</InputLabel>
                                                <Select
                                                    name='statusCode'
                                                    onChange={handleChange}
                                                    required={statusCodeRequired}
                                                    value={patientInfo?.statusCode}
                                                    label={statusCodeLabel}
                                                // onChange={handleChange('genderCode')}
                                                >
                                                    {statusCodeValues?.map(({ value, code }) => {
                                                        return (
                                                            <MenuItem value={code}>{value}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }

                                    {
                                        showAllFields == "All Fields" &&
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>{subscriberRelationshipCodeLabel}</InputLabel>
                                                <Select
                                                    name='subscriberRelationshipCode'
                                                    onChange={handleChange}
                                                    value={patientInfo?.subscriberRelationshipCode}
                                                    label={subscriberRelationshipCodeLabel}
                                                // onChange={handleChange('subscriberRelationshipCode')}
                                                >
                                                    {subscriberRelationshipCodeValues?.map(({ value, code }) => {
                                                        return (
                                                            <MenuItem value={code}>{value}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                            <UnicareNextBtn setSelectedTab={setSelectedTab} handleSubmit={handleSubmit} />
                        </>
                }

            </Paper>

        </LocalizationProvider>



    )
}

export default DefaultUnicarePatientInfo
