import { Grid, Typography } from '@mui/material'
import React from 'react'

const FormSectionHeading = ({heading}) => {
    return (
        <Grid item xs={12}>
            <Typography variant="body1" fontWeight='500'>
                {heading}
            </Typography>
        </Grid>
    )
}

export default FormSectionHeading