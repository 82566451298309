import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react'
import { sharedStyles } from '../../sharedStyles';
import { Box } from '@mui/material';
import UseWidth from '../../custom-hooks/UseWidth';
import { getAllCDcodes } from '../../services/pVerify';
import { failedApiReq } from '../../utils/reusableFunctions';
import DebouncedSearch from '../SearchFields/DebouncedSearch';
import ServiceInfoList from '../eligibility/paForms/components/ServicesInfoList';
import GreenButton from '../claims/GreenButton';

const CdTable = ({onRowSelected, doneButtonAction,showSelectedCodes,handleRemove,serviceInfoArray,setServiceInfoArray }) => {
    const {width} = UseWidth();
    const [gridApi, setGridApi] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const perPage = width <= 900 ? 20 : 10;

    const onGridReady = (params) => {
        setGridApi(params.api);
    };


    const columnDefs = () => {
        return [
            { headerName: 'ID', field: 'id', filter: true, width: 100, resizable: false },
            { headerName: 'ICD Code', field: 'icdCode', filter: true, width: 200, resizable: false },
            {
                headerName: 'Long Description', field: 'longDescription', filter: true, flex:1, resizable: true,
            },
            { headerName: 'Code Type', field: 'codyType', width: 200, filter: true, resizable: true },
             { headerName: 'Version', field: 'version', width: 100, filter: true, resizable: true},
        ];
    };


    const gridOptions = {
        pagination: true,
        paginationPageSize: width <= 900 ? 20 : 10,
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
        overlayNoRowsTemplate: `
        <span class="ag-overlay-loading-center">
            Loading
        </span>
    `,
    onRowClicked: (event) => {
        if (onRowSelected) {
            onRowSelected(event.data); 
        }
    }
    };

    useEffect(() => {
            if (gridApi) {
              const dataSource = {
                getRows: async (params) => {
                  const page = params.endRow / perPage;
                  try {
                    gridApi.showLoadingOverlay();
                      const data = await getAllCDcodes(page, perPage, searchKeyword);
                      if (data?.data) {
                          const dataArray = data?.data?.map(({ icdCode, fullDescription, longDescription, id, version, icdcodeType }) => ({
                              id: id || 'N/A',
                              icdCode: icdCode || 'N/A',
                              fullDescription: fullDescription || 'N/A',
                              longDescription: longDescription || 'N/A',
                              version: version || 'N/A',
                              codyType: icdcodeType || 'N/A'

            }))
                params.successCallback(dataArray, data.totalCount);
                    }
                   
                  }
                  catch (e) {
                    params.successCallback([], 0);
                    failedApiReq(e)
                  }
                  finally {
                    gridApi.hideOverlay();
                  }
                }
              }
        
              gridApi.setDatasource(dataSource);
            }
          }, [gridApi, searchKeyword]);


    return (
       <>
        <DebouncedSearch onSearchChange={setSearchKeyword}
          placeHolder={'Search by Description'}
          sx={sharedStyles?.mv1}
        />
        <Box className="ag-theme-quartz"
            sx={sharedStyles?.responsiveTableHeight}>
            <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage}
                cacheBlockSize={perPage}
                onGridReady={onGridReady}
                rowHeight={60}
                columnDefs={columnDefs()}
                overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
                overlayNoRowsTemplate='No Cpt Codes Found!'
                gridOptions={gridOptions}
            />
        </Box>
        {showSelectedCodes &&
        <ServiceInfoList
        serviceInfoArray={serviceInfoArray}
        onRemove={handleRemove}
        setServiceInfoArray={setServiceInfoArray}
        hideReqFieldsButton={true}
    />}
        {doneButtonAction &&
          <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
            <GreenButton variant="contained" onClick={doneButtonAction}>
            Done
          </GreenButton>
          </Box>
        }
       </>
    )
}

export default CdTable
