import React, { useContext } from 'react'
import DefaultUnicareRequestingProvider from '../../components/Unicare/DefaultUnicareRequestingProvider'
import { UnicareContext } from '../../../../../context/priorAuthorizationForms/UnicareArContext'

const UnicareArRequestingProviderForm = () => {
    const { requestingProvider, setRequestingProvider, setSelectedTab, selectedTab } = useContext(UnicareContext);

    return (
        <DefaultUnicareRequestingProvider
            requestingProvider={requestingProvider}
            setRequestingProvider={setRequestingProvider}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
        />
    )
}

export default UnicareArRequestingProviderForm
