import React, { useContext } from 'react'
import DefaultHsPatientForm from '../../components/SilverSummitHealthPlanHs/DefaultHsPatientForm'
import {  conditionTypesFloridaBlueAr, floridaBlueArServiceTypes, impServiceTypeCodesForFloridaBlueAr } from '../../../../../utils/staticData'
import { FloridaBlueArContext } from '../../../../../context/priorAuthorizationForms/FloridaBlueArContext'

const PatientFloridaBlueArForm = () => {
    const {  setSelectedTab, patient, setPatient,onceCompleted,serviceInfo:serviceType} = useContext(FloridaBlueArContext)
    const servicTypeCode = floridaBlueArServiceTypes?.find(e => e?.value === serviceType?.serviceType)?.code
    const reqCondtionCode =  impServiceTypeCodesForFloridaBlueAr?.find(e => e === servicTypeCode)
  return (
    <DefaultHsPatientForm
    setSelectedTab={setSelectedTab} 
    formData={patient} 
    setFormData={setPatient} 
    importantFields={['lastName', 'firstName', 'birthDate', 
      ...(reqCondtionCode ? ['conditionCode'] : []),
    ]}
    fieldsToHide={['suffix']}
    dateLimits={{min:'1870-01-01',max:'2024-12-25'}}
    onceCompleted={onceCompleted}
    showConditionCode={reqCondtionCode}
    conditionCodes={conditionTypesFloridaBlueAr}
/>

  )
}

export default PatientFloridaBlueArForm