import React, { useEffect, useState } from 'react'
import DebouncedSearch from '../../components/SearchFields/DebouncedSearch';
import { sharedStyles } from '../../sharedStyles';
import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { getAllRxInventory } from '../../services/pVerify';
import { failedApiReq, onAllGridsReady } from '../../utils/reusableFunctions';
import { basicGridOptions } from '../../utils/staticData';
import ProfileRxColumnDefs from '../../components/Profile/RxInventory/ProfileRxColumnDefs';

const RxInventory = () => {
    
    // states
    const [gridApi, setGridApi] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const perPage = 10;

    useEffect(() => {
            if (gridApi) {
              const dataSource = {
                getRows: async (params) => {
                  const page = params.endRow / perPage;
                  try {
                    gridApi.showLoadingOverlay();
                      const data = await getAllRxInventory(page, perPage, searchKeyword);
                      if (data?.data) {
                          const dataArray = data?.data?.map(({ drugDescription, productCode, productCodequalifier, id, strength, deaSchedule }) => ({
                              id: id || 'N/A',
                              drugDescription: drugDescription || 'N/A',
                              productCode: productCode || 'N/A',
                              productCodequalifier: productCodequalifier || 'N/A',
                              strength: strength || 'N/A',
                              deaSchedule: deaSchedule || 'N/A'

            }))
                params.successCallback(dataArray, data.totalCount);
                    }
                   
                  }
                  catch (e) {
                    params.successCallback([], 0);
                    failedApiReq(e)
                  }
                  finally {
                    gridApi.hideOverlay();
                  }
                }
              }
        
              gridApi.setDatasource(dataSource);
            }
          }, [gridApi, searchKeyword]);


    return (
       <>
        <DebouncedSearch onSearchChange={setSearchKeyword}
          placeHolder={'Search by Description'}
          sx={sharedStyles?.mv1}
        />
        <Box className="ag-theme-quartz"
            sx={sharedStyles?.responsiveTableHeight}>
            <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage}
                cacheBlockSize={perPage}
                onGridReady={(params) => onAllGridsReady(params,setGridApi)}
                rowHeight={60}
                columnDefs={ProfileRxColumnDefs()}
                overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
                overlayNoRowsTemplate='No Record Found!'
                gridOptions={basicGridOptions}
            />
        </Box>
        
       </>
    )
}

export default RxInventory