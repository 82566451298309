import React, { useContext } from 'react'
import DefaultRenderingProviders from '../../components/MercyCare/DefaultRenderingProviders'
import { MercyGroupContext } from '../../../../../context/priorAuthorizationForms/MercyCareContext';

const MercyCareDefaultRenderingProviders = () => {
    const {setSelectedTab, renderingProvider, setRenderingProvider} = useContext(MercyGroupContext);
  return (
    <DefaultRenderingProviders 
        renderingProvider={renderingProvider}
        setSelectedTab={setSelectedTab}
        setRenderingProvider={setRenderingProvider}
    />
  )
}

export default MercyCareDefaultRenderingProviders