import React, { useContext } from 'react'
import { MercyGroupContext } from '../../../../../context/priorAuthorizationForms/MercyCareContext'
import DefaultRequestingProviders from '../../components/MercyCare/DefaultRequestingProviders'

const MeryCareReuquestingProvider = () => {
    const {requestingProvider, setRequestingProvider, setSelectedTab, selectedTab} = useContext(MercyGroupContext)
  
  
    return (
    <DefaultRequestingProviders
      requestingProvider={requestingProvider}
    selectedTab={selectedTab}
    setRequestingProvider={setRequestingProvider}
    setSelectedTab={setSelectedTab}
    />
  )
}

export default MeryCareReuquestingProvider