import { createContext, useState } from "react";
import { convertElementsToObject } from "../../utils/reusableFunctions";
import { defaultMercyCarePayload } from "../../utils/staticData";

const MercyGroupContext = createContext();


const MercyGroupContextProvider = ({children}) => {
    const [patientInfo, setPatientInfo] = useState(convertElementsToObject(defaultMercyCarePayload?.patient?.elements));
    const [selectedTab, setSelectedTab] = useState(0);
   const [subscriberInfo, setSubscriberInfo] = useState(convertElementsToObject(defaultMercyCarePayload?.subscriber?.elements))
    const [datesInfo, setDatesInfo]= useState(convertElementsToObject(defaultMercyCarePayload?.datesInfo?.elements))
    const [providerNotes,setProviderNotes] = useState([]);
    const [additionalInfo, setAdditionalInfo] = useState(convertElementsToObject(defaultMercyCarePayload?.additionalInfo?.elements))
    const [renderingProvider, setRenderingProvider] =useState(convertElementsToObject(defaultMercyCarePayload?.renderingProviders?.elements))
   const [requestingProvider, setRequestingProvider] = useState(convertElementsToObject(defaultMercyCarePayload?.requestingProvider?.elements))
   const [procedureInfo, setProcedureInfo] = useState([]);
   const [diagnosisInfo, setDiagnosesInfo] = useState([]);
   const [onceCompleted, setOnceCompleted] = useState(false) 
   return(
        <MercyGroupContext.Provider value={{patientInfo, procedureInfo, setProcedureInfo, diagnosisInfo, setDiagnosesInfo, onceCompleted, setOnceCompleted,requestingProvider,setRequestingProvider, datesInfo,additionalInfo,renderingProvider,setRenderingProvider, setAdditionalInfo,setProviderNotes, providerNotes,setDatesInfo,setPatientInfo, selectedTab, setSelectedTab, subscriberInfo, setSubscriberInfo}}>
               {children}
        </MercyGroupContext.Provider>
    )
}

export {MercyGroupContext, MercyGroupContextProvider};