import React from 'react'
import AetnaArForms from '../../../components/eligibility/paForms/arForms/Aetna/AetnaArForms';
import AmeriGroupForm from '../../../components/eligibility/paForms/arForms/Amerigroup/AmerigroupForm';
import UnicareArForms from '../../../components/eligibility/paForms/arForms/UNICARE/UnicareArForms';
import FloridaBlueArForms from '../../../components/eligibility/paForms/arForms/FloridaBlue/FloridaBlueArForms';
import MercycareForms from '../../../components/eligibility/paForms/arForms/MercyCare/MercycareForms';
import NoFormAvailable from '../../../components/shared/NoFormAvailable';


const ArForms = ({ selectedPayer }) => {

    const renderForm = () => {
        switch (selectedPayer) {
            case 'AMERIGROUP COMMUNITY CARE':
                return <AmeriGroupForm />
            case 'AETNA':
                return <AetnaArForms />
            case 'UNICARE':
                return <UnicareArForms />
            case "FLORIDA BLUE (BCBS FLORIDA)":
                return <FloridaBlueArForms/>
            case "MERCY CARE ARIZONA": 
                return <MercycareForms />
            default:
                return <NoFormAvailable/>;
        }
    }

    return renderForm();
}

export default ArForms
