import React, { useContext } from 'react'
import DefaultUnicareSubscriberInfo from '../../components/Unicare/DefaultUnicareSubscriberInfo'
import { UnicareContext } from '../../../../../context/priorAuthorizationForms/UnicareArContext';

const UnicareArSubscriberForm = () => {
    const { subscriberInfo, setSubscriberInfo, setSelectedTab } = useContext(UnicareContext);

  return (
    <DefaultUnicareSubscriberInfo 
        setSelectedTab={setSelectedTab}
        setSubscriberInfo={setSubscriberInfo}
        subscriberInfo={subscriberInfo}
    />
  )
}

export default UnicareArSubscriberForm
