import { Dashboard, Search, Security,FactCheck } from '@mui/icons-material'
import React, { useState } from 'react'
import Dropdown from './Dropdown'
import { FaRegCircleCheck } from "react-icons/fa6";
import { FaTable } from 'react-icons/fa';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';

const EligibilityDropdown = ({verifiedUserNpi,active}) => {
    const [tabs] = useState([
        {label: "Dashboard",path: "/EligibilityTab", icon: <Dashboard fontSize="small" />},
        {
            label: "Eligibility ",path: "/EligibilityTab?preSelectedTab=1", icon: <FactCheck fontSize="small" />
        },
        {
          label: "MBI Lookup", path: "/EligibilityTab?preSelectedTab=2",icon:<Search fontSize='small'/>
      },
       {label: 'RX Benefits',path: "/EligibilityTab?preSelectedTab=3", icon: <LocalPharmacyIcon fontSize="small" />},
        
    ])
  return (
    <Dropdown
    tabs={tabs}
    verifiedUserNpi={verifiedUserNpi}
    heading={'Eligibility'}
    path={'/EligibilityTab'}
    active={active}
    />
  )
}

export default EligibilityDropdown