import ProfileRxDetails from './ProfileRxDetails';


export const ProfileRxColumnDefs = () => {
    return [
        { headerName: 'Drug Description', field: 'drugDescription', filter: true,  resizable: false,minWidth:350 },
        { headerName: 'Product Code', field: 'productCode', filter: true, width: 200, resizable: false },
        {
            headerName: 'Product Code Qualifier', field: 'productCodequalifier', filter: true, width:230, resizable: true,
        },
        { headerName: 'Strength', field: 'strength', minWidth: 150, filter: true, resizable: true },
         { headerName: 'Dea Schedule', field: 'deaSchedule',  filter: true, resizable: true,minWidth:200},
          { headerName: 'Details', field: '', cellRenderer: (params) => params?.data && ProfileRxDetails(params), minWidth: 140, resizable: false , flex:1},
    ];
};

export default ProfileRxColumnDefs