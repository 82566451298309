import React, { useContext } from 'react'
import DefaultAdditionalInfoForm from '../../components/Unicare/DefaultAdditionalInfoForm'
import { UnicareContext } from '../../../../../context/priorAuthorizationForms/UnicareArContext';

const UnicareArAdditionalInfo = () => {
    const { additionalInfo, setAdditionalInfo, setSelectedTab, selectedTab } = useContext(UnicareContext);


    return (
        <DefaultAdditionalInfoForm
            additionalInfo={additionalInfo}
            setAdditionalInfo={setAdditionalInfo}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
        />
    )
}

export default UnicareArAdditionalInfo
