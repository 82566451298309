import React from 'react'
import DefaultDatesInfo from '../../components/Unicare/DefaultDatesInfo'

const UnicareArDatesInfo = () => {
  return (
    <DefaultDatesInfo />
  )
}

export default UnicareArDatesInfo
