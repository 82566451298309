import React, { useContext } from 'react'
import DefaultSubscriberInfo from '../../components/MercyCare/DefaultSubscriberInfo'
import { MercyGroupContext } from '../../../../../context/priorAuthorizationForms/MercyCareContext'

const MercyCareSubscriberInfo = () => {
const { subscriberInfo, setSubscriberInfo, setSelectedTab} = useContext(MercyGroupContext)
  return (
    <DefaultSubscriberInfo 
    setSelectedTab={setSelectedTab}
    setSubscriberInfo={setSubscriberInfo}
    subscriberInfo={subscriberInfo}
    />
  )
}

export default MercyCareSubscriberInfo