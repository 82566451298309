import { Box, Button, Modal } from '@mui/material';
import React from 'react'
import { styles } from './style';
import { FaCheckCircle } from 'react-icons/fa';
import { clearAllLocalData } from '../../utils/localStorageFunctions';
import { useAuth0 } from '@auth0/auth0-react';
import { logoutRedirection } from '../../utils/reusableFunctions';
import { sharedStyles } from '../../sharedStyles';
import { useNavigate } from 'react-router-dom';
import { logoutUserApiCall } from '../../services/userApis';

const CompletedFormsModal =  ({open,showHistoryButton}) => {
    const navigate = useNavigate()
    const { logout } = useAuth0();
    const handleClose = async () => {
        await logoutUserApiCall()
        clearAllLocalData()
        logout({ logoutParams: { returnTo: logoutRedirection() } })
    }
  return (
   
    <Modal open={open} onClose={handleClose} aria-labelledby='confirmation-modal' aria-describedby='modal-confirmation-description'>
    <Box sx={styles?.submittedFormWrapper}>
    <FaCheckCircle size={60} color="green" />
    <h2>You're All Set!</h2>
    <p style={styles?.submittedFormDescriptions}>
      Thank you for completing all your required forms. At this moment, there's nothing more for you to fill out. 
      We'll keep you updated if there's any new information or next steps needed. Feel free to revisit this page 
      whenever you'd like to review your submissions.
    </p>
    <p style={styles?.submittedFormDescriptions}>
      If you have any further questions or require assistance, please don't hesitate to contact us.
    </p>
    <Box sx={styles?.buttonWrapper}>
                    {showHistoryButton ?
                    <Button  variant='contained' onClick={()=>navigate('/visitsHistory')} color='success'>View History</Button>
                    : null}
                    <Button  variant='contained' onClick={handleClose} color='success'>Logout</Button>
                </Box>
  </Box>
</Modal>
  )
}

export default CompletedFormsModal