import React from 'react'
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { sharedStyles } from '../../sharedStyles';

const InactiveNpi = ({mobView}) => {
  return (
    <div className={`inactive-npi ${mobView && 'inactive-npi-mobile'}`}>
    NPI registeration pending with payers
    <NewReleasesIcon sx={sharedStyles?.ml1}/>
    </div>
  )
}

export default InactiveNpi