import { Box, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styles } from '../styles'
import { sharedStyles } from '../../../../../sharedStyles'
import { UnicarePayload } from '../../../../../utils/staticData'
import toast from 'react-hot-toast'
import { postalCodeFormat } from '../../../../../utils/valueFormatter/Formats'
import UnicareNextBtn from '../../UnicareNextBtn'

const DefaultRequestingProviders = ({ requestingProvider, setRequestingProvider, setSelectedTab, selectedTab }) => {
     const [showAllFields, setShowAllFields] = useState(false);
        const [err, setErr] = useState(false);
        console.log(requestingProvider)
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'roleCode') {
            setRequestingProvider(prevState => {
                return {
                    ...prevState,
                    [name]: value?.value
                }
            })
            return;
        } else if (name === 'zipCode') {
            let formattedValue = postalCodeFormat(value);

            setRequestingProvider(prevState => {
                return {
                    ...prevState,
                    [name]: formattedValue
                }
            });
            return;
        } else if(name === 'fax' || name === 'extension' || name==='taxId' || name === 'phone') {
            const numericValue = value.replace(/[^0-9]/g, '');
            setRequestingProvider(prevState => ({
                ...prevState,
                [name]: numericValue
            }));
            return;
        }  

        setRequestingProvider(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })

    }


    const handleSubmit = () => {
        if (
            !requestingProvider?.roleCode ||
            !requestingProvider?.lastName ||
            !requestingProvider?.stateCode ||
            !requestingProvider?.city ||
            !requestingProvider?.zipCode ||
            !requestingProvider?.phone ||
            !requestingProvider?.contactName ||
            !requestingProvider?.addressLine1
        ) {
            setErr(true);
            toast.error('Please fill all the required fields');
            return;
        };
        setSelectedTab(prev => prev + 1);
    }


    
 

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Requesting Provider Information
            </Typography>
            <RadioGroup
                value={showAllFields}
                onClick={(event) => setShowAllFields(!showAllFields)}
            >
                <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
            </RadioGroup>
            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>{UnicarePayload?.requestingProvider?.elements?.roleCode?.label}*</InputLabel>
                            <Select
                                name={'roleCode'}
                                value={requestingProvider?.roleCode}
                                label={UnicarePayload?.requestingProvider?.elements?.roleCode?.label}
                                error={UnicarePayload?.requestingProvider?.elements?.roleCode?.required && !requestingProvider?.roleCode && err}
                                onChange={(e) => handleChange({
                                    ...e,
                                    target: {
                                        ...e.target,
                                        value: UnicarePayload?.requestingProvider?.elements?.roleCode?.values.find(
                                            elem => elem.value === e.target.value
                                        ) || {}
                                    }
                                })}
                            >
                                {UnicarePayload?.requestingProvider?.elements?.roleCode?.values.map(elem => {
                                    return (
                                        <MenuItem value={elem?.value} key={elem?.code}>
                                            {elem?.value}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>
                                {UnicarePayload?.requestingProvider?.elements?.roleCode?.required && !requestingProvider?.roleCode && err ? UnicarePayload?.requestingProvider?.elements?.roleCode?.errorMessage : 'Required *'}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                   
                {
                            showAllFields && (
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name={'firstName'}
                                        onChange={handleChange}
                                        label={UnicarePayload?.requestingProvider?.elements?.firstName?.label}
                                        value={requestingProvider?.firstName}
                                        inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.firstName?.maxLength }}
                                    />
                                </Grid>
                            )

                    }
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name={'lastName'}
                            onChange={handleChange}
                            label={UnicarePayload?.requestingProvider?.elements?.lastName?.label}
                            value={requestingProvider?.lastName}
                            inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.lastName?.maxLength }}
                            error={UnicarePayload?.requestingProvider?.elements?.lastName?.required && !requestingProvider?.lastName && err}
                            helperText={UnicarePayload?.requestingProvider?.elements?.lastName?.required && !requestingProvider?.lastName && err ? UnicarePayload?.requestingProvider?.elements?.lastName?.errorMessage : 'Required *'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>{UnicarePayload?.requestingProvider?.elements?.stateCode?.label}*</InputLabel>
                            <Select
                                name={'stateCode'}
                                onChange={handleChange}
                                value={requestingProvider?.stateCode}
                                label={UnicarePayload?.requestingProvider?.elements?.stateCode?.label}
                                error={UnicarePayload?.requestingProvider?.elements?.stateCode?.required && !requestingProvider?.stateCode && err}
                            >
                                {UnicarePayload?.requestingProvider?.elements?.stateCode?.values.map(elem => {
                                    return (
                                        <MenuItem value={elem?.value} key={elem?.code}>
                                            {elem?.value}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>
                                {UnicarePayload?.requestingProvider?.elements?.stateCode?.required && !requestingProvider?.stateCode && err ? UnicarePayload?.requestingProvider?.elements?.stateCode?.errorMessage : 'Required *'}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name='city'
                            onChange={handleChange}
                            label={UnicarePayload?.requestingProvider?.elements?.city?.label}
                            value={requestingProvider?.city}
                            inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.city?.maxLength }}
                            error={UnicarePayload?.requestingProvider?.elements?.city?.required && !requestingProvider?.city && err}
                            helperText={UnicarePayload?.requestingProvider?.elements?.city?.required && !requestingProvider?.city && err ? UnicarePayload?.requestingProvider?.elements?.city?.errorMessage : 'Required *'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name='zipCode'
                            onChange={handleChange}
                            label={UnicarePayload?.requestingProvider?.elements?.zipCode?.label}
                            value={requestingProvider?.zipCode}
                            inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.zipCode?.maxLength }}
                            error={UnicarePayload?.requestingProvider?.elements?.zipCode?.required && !requestingProvider?.zipCode && err}
                            helperText={UnicarePayload?.requestingProvider?.elements?.zipCode?.required && !requestingProvider?.zipCode && err ? UnicarePayload?.requestingProvider?.elements?.zipCode?.errorMessage : 'Required *'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name='contactName'
                            onChange={handleChange}
                            label={UnicarePayload?.requestingProvider?.elements?.contactName?.label}
                            value={requestingProvider?.contactName}
                            inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.contactName?.maxLength }}
                            error={UnicarePayload?.requestingProvider?.elements?.contactName?.required && !requestingProvider?.contactName && err}
                            helperText={UnicarePayload?.requestingProvider?.elements?.contactName?.required && !requestingProvider?.contactName && err ? UnicarePayload?.requestingProvider?.elements?.contactName?.errorMessage : 'Required *'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name='addressLine1'
                            onChange={handleChange}
                            label={UnicarePayload?.requestingProvider?.elements?.addressLine1?.label}
                            value={requestingProvider?.addressLine1}
                            inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.addressLine1?.maxLength }}
                            error={UnicarePayload?.requestingProvider?.elements?.addressLine1?.required && !requestingProvider?.addressLine1 && err}
                            helperText={UnicarePayload?.requestingProvider?.elements?.addressLine1?.required && !requestingProvider?.addressLine1 && err ? UnicarePayload?.requestingProvider?.elements?.addressLine1?.errorMessage : 'Required *'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name='phone'
                            onChange={handleChange}
                            label={UnicarePayload?.requestingProvider?.elements?.phone?.label}
                            value={requestingProvider?.phone}
                            inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.phone?.maxLength }}
                            error={UnicarePayload?.requestingProvider?.elements?.phone?.required && !requestingProvider?.phone && err}
                            helperText={UnicarePayload?.requestingProvider?.elements?.phone?.required && !requestingProvider?.phone && err ? UnicarePayload?.requestingProvider?.elements?.phone?.errorMessage : 'Required *'}
                        />
                    </Grid>
                    {/* Not Required */}
                    {
                        showAllFields && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        onChange={handleChange}
                                        name='npi'
                                        label={UnicarePayload?.requestingProvider?.elements?.npi?.label}
                                        value={requestingProvider?.npi}
                                        inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.npi?.maxLength }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name='fax'
                                        onChange={handleChange}
                                        label={UnicarePayload?.requestingProvider?.elements?.fax?.label}
                                        value={requestingProvider?.fax}
                                        inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.fax?.maxLength }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name='taxId'
                                        fullWidth
                                        onChange={handleChange}
                                        label={UnicarePayload?.requestingProvider?.elements?.taxId?.label}
                                        value={requestingProvider?.taxId}
                                        inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.taxId?.maxLength }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name='extension'
                                        onChange={handleChange}
                                        label={UnicarePayload?.requestingProvider?.elements?.extension?.label}
                                        value={requestingProvider?.extension}
                                        inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.extension?.maxLength }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        onChange={handleChange}
                                        name='addressLine2'
                                        label={UnicarePayload?.requestingProvider?.elements?.addressLine2?.label}
                                        value={requestingProvider?.addressLine2}
                                        inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.addressLine2?.maxLength }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        onChange={handleChange}
                                        name='payerAssignedProviderId'
                                        label={UnicarePayload?.requestingProvider?.elements?.payerAssignedProviderId?.label}
                                        value={requestingProvider?.payerAssignedProviderId}
                                        inputProps={{ maxLength: UnicarePayload?.requestingProvider?.elements?.payerAssignedProviderId?.maxLength }}
                                    />
                                </Grid>
                            </>
                        )
                    }
                </Grid>
            </Box>
            <UnicareNextBtn handleSubmit={handleSubmit} setSelectedTab={setSelectedTab}  />
        </Paper>
    )
}

export default DefaultRequestingProviders