import React, { useContext } from 'react'
import DefaultUnicarePatientInfo from '../../components/Unicare/DefaultUnicarePatientInfo'
import { UnicareContext } from '../../../../../context/priorAuthorizationForms/UnicareArContext';

const UnicareArPatientInfoForm = () => {
  const {  patientInfo, setPatientInfo, selectedTab, setSelectedTab, onceCompleted } = useContext(UnicareContext);
  
    return (
        <DefaultUnicarePatientInfo 
            patientInfo={patientInfo}
            selectedTab={selectedTab}
            setPatientInfo={setPatientInfo}
            setSelectedTab={setSelectedTab}
            onceCompleted={onceCompleted}
            />
  )
}

export default UnicareArPatientInfoForm
