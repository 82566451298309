import axios from "axios";

import { API_BASE_URL, API_BASE_URL_NEW } from "../utils/staticData";
import { userGetAxios } from "./userApis";


export const getEligibilitySummaryResponse = async (requestId) => {
  const url = `${API_BASE_URL_NEW}/api/Eligibility/pVerify/GetEligibilitySummaryResponse/${requestId}`;

  try {
    const response = await userGetAxios(url);
    return response;
  } catch (error) {
    console.error('Error fetching eligibility summary response:', error);
    // Instead of throwing the error, return a custom error object
    return {
      error: true,
      message: 'Failed to fetch eligibility summary response',
      details: error.message
    };
  }
};

export const getServiceReviewsResponse = async(sr,pId,tDate,id) => {
  const url = `${API_BASE_URL_NEW}/api/Availity/ServiceReviews/GetServiceReviewsDataById/${id}`;
  try {
    const response = await userGetAxios(url);
    return response;
  } catch (error) {
    console.log('Error fetching service review', error);
    return {
      error: true,
      message: 'Failed to fetch service review',
      details: error.message
    };
  }
} 