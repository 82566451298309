import React, { useEffect, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    CircularProgress,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    Card,
    CardContent,
    Modal,
    IconButton,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { styles } from './styles';
import { sharedStyles } from '../../sharedStyles'
import PageLoader from '../../components/loader/PageLoader'
import TableHeader from '../../components/TableHeader';
import { availityServicePayload, paFormErrors, patientGenders, policyHolderRelationship } from '../../utils/staticData';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DefaultSelect from '../../components/shared/DefaultSelect';
import { get_payersdata, getAllPayerSubTypes, getDistinctPayers } from '../../services/PayerService';
import { get_GetPatientDataById } from '../../services/addPatient';
import { getLocalRequestNpi, handlePaginationScroll } from '../../utils/reusableFunctions';
import GreenButton from '../../components/claims/GreenButton';
import { getServiceReviewsByParam } from '../../services/pVerify';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import CptTable from '../../components/table/CptTable';
import CdTable from '../../components/table/CdTable';
import FormSectionHeading from '../../components/eligibility/FormSectionHeading';
import { ymdDateFormat } from '../../utils/DateFunctions';
import PaginatedSearchSelect from '../../components/SearchFields/PaginatedSearchSelect';
import { colors } from '../../utils/colors';

const NewSubmitAvaility = () => {
    // states
    const [errors, setErrors] = useState({ 
        birthDate: false, 
        lastName: false, 
        firstName: false, 
        insuranceName: false, 
        patientSubscriberRelationshipCode: false,
        patientFirstName:false,
        patientLastName:false,
        patientBirthDate:false,
        patientSubscriberRelationshipCode:false,
        insuranceName:false,
       

    });
    const [patientSelected, setPatientSelected] = useState(null)
    const [loader, setLoader] = useState({ patient: false, payersData: true,form:false, typeCodes: false })
    const [page, setPage] = useState(1)
    const [formData, setFormData] = useState(availityServicePayload)
    const [payersData, setPayersData] = useState([])
    const [showAllFields, setShowAllFields] = useState(false);
    const [allTypeCodes, setAllTypeCodes] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectedCptCodes, setSelectedCptCodes] = useState([])
    const [selectedIcdCodes, setSelectedIcdCodes] = useState([])
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    
    const navigate = useNavigate()
    // useEffects

    useEffect(() => {
        patientSelected?.id && getPatientDetails(patientSelected, setLoader, setFormData, formData)
    }, [patientSelected])

    useEffect(() => {
        fetchData()
    }, [page, searchTerm])

    useEffect(()=>{
        getAllTypeCodes()
    },[])

    // functions

    // handling field values
    const handleChange = (field) => (event,selectedVal) => {
        const { value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]:  field !== 'insuranceName' ? value : selectedVal,
        }));
    };

    // handling date fields values
    const handleDateChange = (field) => (date) => {
        setFormData({
            ...formData,
            [field]: date,
        });
    };

    // getting data for all payers list
    const fetchData = async () => {
        setLoader({ ...loader, payersData: true });
        try {
            let allOptions = []
            const allPayersdata = await getDistinctPayers(page, 10,null, searchTerm);
            const newOptions = allPayersdata?.data?.map((payer) => ({label:payer?.payerName,value:`${payer?.id} # ${payer?.payerName}`}) ) || [];
            if (page === 1) {
                setPayersData(newOptions);
            } else {
                allOptions = [...payersData, ...newOptions]

                setPayersData(allOptions);
            }
            setHasMore(allOptions?.length < allPayersdata?.totalCount);
        } catch (error) {
            console.log('Error fetching payers data:', error);
        } finally {
            setLoader({ ...loader, payersData: false });
        }
    };

    const handleSearch = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setPage(1);
    };

    // handling when patient is selected
    const getPatientDetails = async () => {
        if (patientSelected?.id) {
            try {
                setLoader({ ...loader, patient: true })
                const { data: { firstName: patientFirstName, middleName: patientMiddleName, lastName: patientLastName, dob, memberId: subscriberMemberId, patientInsuranceDetails } } = await get_GetPatientDataById(patientSelected?.id)
                setFormData({
                    ...formData,
                    patientFirstName,
                    patientMiddleName,
                    patientLastName,
                    patientBirthDate: dayjs(dob).format('YYYY-MM-DD'),
                    subscriberMemberId,
                    insuranceName: patientInsuranceDetails?.length ? patientInsuranceDetails?.[0] : ''

                })
            }
      
            catch (e) {
                console.log(e)
            }
            finally {
                setLoader({ ...loader, patient: false })
            }
        }
    }
    // form submission
    const handleSubmit = async () => {
        const { patientFirstName, patientLastName, patientBirthDate, patientSubscriberRelationshipCode, insuranceName } = formData;

        const requiredFields = { patientFirstName, patientLastName, patientBirthDate, patientSubscriberRelationshipCode, insuranceName };

        const allErrors = Object.fromEntries(
            Object.entries(requiredFields).map(([key, value]) => [key, !value])
        );

        if (Object.values(allErrors).includes(true)) {
            toast.error('Please enter all required information to continue.');
            setErrors({ ...errors, ...allErrors });
        } else {
            setErrors({ ...errors, ...Object.keys(requiredFields).reduce((acc, key) => ({ ...acc, [key]: false }), {}) });


        try{
            setLoader({...loader,form:true})
            const formSubmission =  await  getServiceReviewsByParam(
                {
                    ...formData,
                    patientBirthDate: dayjs(formData?.patientBirthDate).format('YYYY-MM-DD'),
                    npiNumber: JSON.parse(localStorage?.getItem('selectedNpi'))?.npiNumber,
                    icdCodeIds: selectedIcdCodes?.length ? selectedIcdCodes?.map(e => `${e?.id}`) : null,
                    cptCodeIds: selectedCptCodes?.length ? selectedCptCodes?.map(e => `${e?.id}`) : null,
                    certificationIssueDate: formData?.certificationIssueDate ? ymdDateFormat(formData?.certificationIssueDate)
                    : null,
                    fromDate:dayjs(formData?.fromDate).format('YYYY-MM-DD'),
                    toDate:dayjs(formData?.toDate).format('YYYY-MM-DD'),
                    transactionDate:ymdDateFormat(new Date()),
                    payerId: formData?.insuranceName?.value?.split(' # ')?.[0],
                    insuranceName:formData?.insuranceName?.label,
                });

            if(!formSubmission?.data?.isSuccessful){
                const errorMessage = JSON.parse(formSubmission?.data?.message)?.moreInformation
                errorMessage && toast.error(errorMessage)
                return
            }
            navigate('/detailsPA', {state: {data: formSubmission?.data}})
        }
        catch(e){
            console.log(e)
        }
        finally{
            setLoader({...loader,form:false})
        }
        }
    }

    // getting all type codes
    const getAllTypeCodes = async () => {
        try {
            setLoader({ ...loader, typeCodes: true })
            const typeCodes = await getAllPayerSubTypes()
            if (typeCodes?.length) {
                setAllTypeCodes(typeCodes?.map(e => {
                    return (
                        {
                            value:e?.subTypeId,
                            label:e?.subTypeValue
                        }
                )
                }))
            }
        }
        catch (e) {
            setLoader({ ...loader, typeCodes: false })
            console.log(e)
        }
    }

    const handleAddCodes = (item, codeType) => {
        const updateSelectedCodes = (setter, selectedItems) => {
            if (selectedItems.some((e) => e?.id === item?.id)) {
                toast.error('This diagnosis is already selected');
                return selectedItems;
            }
            return [...selectedItems, item];
        };
    
        if (codeType === 'cptCode') {
            setSelectedCptCodes((prev) => updateSelectedCodes(setSelectedCptCodes, prev));
        } else {
            setSelectedIcdCodes((prev) => updateSelectedCodes(setSelectedIcdCodes, prev));
        }
    };
    
    const handleRemoveCodes = (idToRemove, codeType) => {
        const updateSelectedCodes = (setter) => {
            setter((prev) => prev.filter((item) => item.id !== idToRemove));
        };
    
        if (codeType === 'cptCode') {
            updateSelectedCodes(setSelectedCptCodes);
        } else {
            updateSelectedCodes(setSelectedIcdCodes);
        }
    };
    
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Search PA for Patient
                </Typography>

                <div style={sharedStyles?.mt10px}>
                    <TableHeader setFilterPatient={setPatientSelected} idNeeded
                        handleCrossValue={() => setFormData(availityServicePayload)}
                    />
                </div>
                {loader?.patient ?
                    <PageLoader />
                    :
                    <>
                    <FormControl>
                        <FormLabel id='show-required-fields'>Form Type</FormLabel>
                            <RadioGroup
                            row
                                    aria-labelledby='show-required-fields'
                                    defaultValue={showAllFields}
                                    value={showAllFields}
                                    onChange={(event) => setShowAllFields(event.target.value === "true")}
                                >
                                    <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Quick Search" />
                                    <FormControlLabel value={true} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Advance Search" />
                                </RadioGroup>
                       
                    </FormControl>
                        <Box sx={styles?.commonPaperContainer}>
                            <Grid container spacing={3}>
                           {/* patient info */}
                                {showAllFields &&
                                    <FormSectionHeading heading={'Patient Information'} />
                                }
                                <Grid item xs={12} md={showAllFields  ? 4 : 6}>
                                    <TextField
                                        fullWidth
                                        label="Patient First Name"
                                        onChange={handleChange('patientFirstName')}
                                        required
                                        value={formData?.patientFirstName}
                                        error={errors?.patientFirstName}

                                    />
                                </Grid>
                                {showAllFields  && <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        label="Patient Middle Name"
                                        onChange={handleChange('patientMiddleName')}
                                        value={formData?.patientMiddleName}
                                    />
                                </Grid>}
                                <Grid item xs={12} md={showAllFields  ? 4 : 6}>
                                    <TextField
                                        fullWidth
                                        label="Patient Last Name"
                                        onChange={handleChange('patientLastName')}
                                        required
                                        value={formData?.patientLastName}
                                        error={errors?.patientLastName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={showAllFields ? 4 : 6}>
                                    <DatePicker
                                        label="Patient Date of Birth"
                                        value={dayjs(formData.patientBirthDate)}
                                        onChange={handleDateChange('patientBirthDate')}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                required: true,
                                                error: !!errors?.patientBirthDate,
                                            },
                                        }}
                                        sx={errors?.patientBirthDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                    />
                                </Grid>
                                {showAllFields  &&
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <InputLabel>Patient Gender</InputLabel>
                                            <Select
                                                value={formData?.patientGender}
                                                label="Patient Gender"
                                                onChange={handleChange('patientGender')}
                                            >
                                                {patientGenders?.map(({ value, label }) => {
                                                    return (
                                                        <MenuItem value={label}>{label}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                 <Grid item xs={12} md={showAllFields ? 4 : 6}>
                                    <FormControl fullWidth  error={errors?.patientSubscriberRelationshipCode}>
                                        <InputLabel>Patient's Relationship to Subscriber</InputLabel>
                                        <Select
                                            value={formData.patientSubscriberRelationshipCode}
                                            label="Patient's Relationship to Subscriber"
                                            onChange={handleChange('patientSubscriberRelationshipCode')}
                                        >
                                            {
                                                !!policyHolderRelationship?.length && policyHolderRelationship?.map((item, index) => (
                                                    <MenuItem value={item} key={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* dates info */}
                                {showAllFields  && 
                                <>
                               <FormSectionHeading heading={'Dates Information'}/>
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        label="Transaction Date"
                                        value={dayjs(formData.transactionDate)}
                                        onChange={handleDateChange('transactionDate')}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        label="From Date"
                                        value={dayjs(formData.fromDate)}
                                        onChange={handleDateChange('fromDate')}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        label="To Date"
                                        value={dayjs(formData.toDate)}
                                        onChange={handleDateChange('toDate')}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                </>
                                }

                                {/* subscriber info */}
                                {showAllFields  && 
                                <>
                                <FormSectionHeading heading={'Subscriber Information'}/>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        label="Subscriber First Name"
                                        onChange={handleChange('subscriberFirstName')}
                                        value={formData?.subscriberFirstName}
                                        error={errors?.subscriberFirstName}

                                    />
                                </Grid>
                                 <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        label="Subscriber Middle Name"
                                        onChange={handleChange('subscriberMiddleName')}
                                        value={formData?.subscriberMiddleName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={showAllFields  ? 4 : 6}>
                                    <TextField
                                        fullWidth
                                        label="Subscriber Last Name"
                                        onChange={handleChange('subscriberLastName')}
                                        value={formData?.subscriberLastName}
                                        error={errors?.subscriberLastName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Subscriber Member ID"
                                        onChange={handleChange('subscriberMemberId')}
                                        value={formData?.subscriberMemberId}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Subscriber Suffix"
                                        onChange={handleChange('subscriberSuffix')}
                                        value={formData?.subscriberSuffix}
                                    />
                                </Grid>
                                </>
}

                                {/* insurance info */}
                                {showAllFields  && 
                                <FormSectionHeading heading={'Insurance Information'}/>
}
                                <Grid item xs={12} md={6}>
                                         <PaginatedSearchSelect
                                        options={payersData}
                                        value={formData?.insuranceName || null}
                                        onSearch={handleSearch}
                                        onLoadMore={() => setPage(prev => prev + 1)}
                                        isLoading={loader?.payersData}
                                        hasMore={hasMore}
                                        placeholder="Insurance Name *"
                                        onChange={(selectedOption) => setFormData({...formData,insuranceName:selectedOption})}
                                        error={errors?.insuranceName}
                                        color={colors?.themeBlue}
                                    />
                                </Grid>
                                {showAllFields  &&     <Grid item xs={12} md={6}>
                                     <FormControl fullWidth>
                                            <InputLabel>Request Type Code</InputLabel>
                                            <Select
                                                value={formData?.requestTypeCode}
                                                label="Request Type Code"
                                                onChange={handleChange('requestTypeCode')}
                                            >
                                                {allTypeCodes?.map(({ value, label }) => {
                                                    return (
                                                        <MenuItem value={value}>{label}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                </Grid>
}
                               

                                <Grid item xs={12} md={showAllFields  ? 12 : 6}>
                                    <TextField
                                        fullWidth
                                        label="NPI"
                                        inputProps={{
                                            maxLength: 60,
                                        }}
                                        required
                                        // readOnly
                                        // disabled
                                        value={getLocalRequestNpi()}
                                    />
                                </Grid>

                                {
                                    showAllFields  &&
                                    <>
                                       <FormSectionHeading heading={'Other Information'}/>

                                        <Grid item xs={12} md={6}>
                                            <DatePicker
                                                label="Certification Issue Date"
                                                value={formData.certificationIssueDate ? dayjs(formData.certificationIssueDate) : null}
                                                onChange={handleDateChange('certificationIssueDate')}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Certification Number"
                                                onChange={handleChange('certificationNumber')}
                                                value={formData?.certificationNumber}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Reference Number"
                                                onChange={handleChange('referenceNumber')}
                                                value={formData?.referenceNumber}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Status Code"
                                                onChange={handleChange('statusCode')}
                                                value={formData?.statusCode}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Session ID"
                                                onChange={handleChange('sessionId')}
                                                value={formData?.sessionId}
                                            />
                                        </Grid>
                                        <FormSectionHeading heading={'Codes Information'}/>
                                        <Grid item xs={12} md={6}>
                                            <Card sx={{
                                                p: 1,
                                                boxShadow: 3,
                                                borderRadius: 2,
                                                textAlign: "center",
                                                cursor:'pointer'
                                            }}>
                                                <CardContent
                                                onClick={() => setShowModal('CPT')}
                                                >
                                                    <Typography variant="h6">CPT Codes</Typography>
                                                    <Typography variant='body'>{!!selectedCptCodes?.length && selectedCptCodes?.map(e => e?.CptCode)?.join(', ')}</Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Card sx={{
                                                p: 1,
                                                boxShadow: 3,
                                                borderRadius: 2,
                                                textAlign: "center",
                                                cursor:'pointer'
                                            }}>
                                                <CardContent
                                                onClick={() => setShowModal('ICD')}
                                                >
                                                    <Typography variant="h6">ICD Codes</Typography>
                                                    <Typography variant='body'>{!!selectedIcdCodes?.length && selectedIcdCodes?.map(e => e?.icdCode)?.join(', ')}</Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </>
                                }


                            </Grid>
                        </Box>
                        <Grid
                            item
                            xs={12}
                            display={'flex'}
                            sx={sharedStyles?.justifyEnd}
                            gap={1}
                        >
                            <GreenButton onClick={handleSubmit} disabled={loader?.form} >
                                {loader?.form ? <CircularProgress color='inherit' size={17}/> : 'Submit'}
                            </GreenButton>
                        </Grid>

                    </>
                }
                 <Modal open={showModal} onClose={() => setShowModal(false)}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 3,
                        borderRadius: 2,
                        width:'80%',
                        maxHeight: '80%',
                        overflow:'scroll'
                    }}
                >
                    <IconButton
                        onClick={() => setShowModal(false)}
                        sx={{ position: "absolute", top: 10, right: 10 }}
                    >
                       <Close/>
                    </IconButton>

                  {showModal === 'CPT' ?  <CptTable
                    onRowSelected={(item)=>handleAddCodes(item,'cptCode')}
                    doneButtonAction={()=>setShowModal(false)}
                    showSelectedCodes = {!!selectedCptCodes?.length}
                    serviceInfoArray={selectedCptCodes}
                    setServiceInfoArray={setSelectedCptCodes}
                    handleRemove={(id) => handleRemoveCodes(id,'cptCode')}
                    /> 
                    :
                  <CdTable 
                  onRowSelected={handleAddCodes}
                  doneButtonAction={()=>setShowModal(false)}
                    showSelectedCodes = {!!selectedIcdCodes?.length}
                    serviceInfoArray={selectedIcdCodes}
                    setServiceInfoArray={setSelectedIcdCodes}
                    handleRemove={handleRemoveCodes}
                  />
                  }
                </Box>
            </Modal>
            </Paper>
        </LocalizationProvider>
    );
};

export default NewSubmitAvaility;
