import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, } from 'react'
import { get_registrationUserByUserId } from '../../services/registration';
import { useNavigate, } from 'react-router-dom';
import { handleLogout } from '../../services/userApis';

const LandingPage = () => {
    const { isAuthenticated, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();



    const fetchUserAttributes = async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                token && localStorage.setItem('authToken',token)
                // Get ID token claims
                const idTokenClaims = await getIdTokenClaims();
                const userId = idTokenClaims['sub'];
                const data = await get_registrationUserByUserId(userId);
                if (data?.isSuccessful) {
                    localStorage.setItem('user', JSON.stringify(data?.data))
                    localStorage.setItem('selectedNpi', JSON.stringify(data?.data?.npiInfoList[0]));
                    navigate('/home');
                }
                else {
                    navigate('/Registration');
                }

            }
            catch (error) {
                console.log('Error fetching user attributes:', error);
                handleLogout()
            }
        }

    };

    useEffect(() => {
        fetchUserAttributes();
    }, [isAuthenticated, getAccessTokenSilently]);
    return (
        <div></div>
    )
}

export default LandingPage