import React from 'react';
import { 
  Container, 
  Box, 
  Typography, 
  Grid, 
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import { patientCoveredData } from '../../utils/staticData';
import { styles } from './styles';
import RxMessageDetails from './RxMessageDetails';
import RxPatientDetails from './RxPatientDetails';
import RxPrescriberDetails from './RxPrescriberDetails';
import RxMedicationDetails from './RxMedicationDetails';
import RxBenefitsResponseDetails from './RxBenefitsResponseDetails';

// Custom theme
const theme = createTheme(styles?.themeStyle);


// Main component
const RxResponseDetails = () => {
  const { Body, Header } = patientCoveredData;
  const patient = Body.BenefitResponse.Patient;
  const prescriber = Body.BenefitResponse.Prescriber;
  const medication = Body.BenefitResponse.MedicationRequestedInformation;
  
  return (
    <ThemeProvider theme={theme}>
      <Box sx={styles?.containerBox}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom sx={styles?.mainHeading}>
            Patient Benefit Response
          </Typography>
          
          <Grid container spacing={3}>
            {/* Message Details */}
              <RxMessageDetails Header={Header} />
            
            {/* Patient Information */}
           <RxPatientDetails patient={patient}/>
            
            {/* Prescriber Information */}
            <RxPrescriberDetails prescriber={prescriber}/>
            
            {/* Medication Information */}
            <RxMedicationDetails medication={medication}/>
            
            {/* Response Info */}
            <RxBenefitsResponseDetails response={Body?.BenefitResponse?.Response}/>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default RxResponseDetails;
