import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { PatientActionBtn } from './configuration/PatientTableConfiguration';
import { formatGridArray } from '../../Pages/Patients/PatientFunctions';
import { sharedStyles } from '../../sharedStyles';
import { failedApiReq } from '../../utils/reusableFunctions';
import { get_GetAllPatientsDataAsync } from '../../services/addPatient';
import ToolTipCellRenderer from '../cellRenderers/ToolTipCellRenderer';
import ConditionalButtonElseTextRender from '../cellRenderers/ConditionalButtonElseTextRender';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import UseWidth from '../../custom-hooks/UseWidth';
import DebouncedSearch from '../../components/SearchFields/DebouncedSearch'

const GridComponentPatients = () => {
  const [gridApi, setGridApi] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const { width } = UseWidth();
  const perPage = width <= 900 ? 20 : 10;
  const navigate = useNavigate()

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: async (params) => {
          const page = params.endRow / perPage;
          try {
            gridApi.showLoadingOverlay();
            const data = await get_GetAllPatientsDataAsync(null, page, perPage, searchKeyword); // Pass searchKeyword to API
            const dataArray = formatGridArray(data?.data)
            params.successCallback(dataArray, data.totalCount);
          }
          catch (e) {
            params.successCallback([], 0);
            failedApiReq(e)
          }
          finally {
            gridApi.hideOverlay();
          }
        }
      }

      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, searchKeyword]);

  const insuranceButton = ({data}) => {
    const {Patient:name,Insurance,DOB,Appt,state} = data 
    const [fName, lName] = name.split(' ')
    const apptQuery = Appt?.[0] ? `&appt=${Appt[0]}` : '';

    return(
      <ConditionalButtonElseTextRender text={Insurance} buttonText={'Run Discovery Search'}
        onClick={
          () => navigate(`/discoveryTab?preSelectedTab=2&firstName=${fName}&lastName=${lName}&dob=${DOB}${apptQuery}&state=${state}`)
        }
      /> 
    )
  }

  const columnDefs = () => {
    return [
      { headerName: 'Date Added', field: 'Date_Added', width: 170,  resizable: false },
      { headerName: 'Appt', field: 'Appt', width: 170,  resizable: false, cellRenderer: (params) => params?.data && <ToolTipCellRenderer text={params?.data?.Appt} /> },
      { headerName: 'Patient', field: 'Patient',  width: 232, resizable: false },
      { headerName: 'Phone', field: 'Phone',  width: 123, resizable: false },
      { headerName: 'Insurance', field: 'Insurance', width: 227, minWidth:227, resizable: false, cellRenderer: (params) => params?.data && insuranceButton(params)},
      { headerName: '', field: '', cellRenderer: (params) => params?.data && PatientActionBtn(params), flex: 1, minWidth: 140, resizable: false },
    ];
  };

  return (
    <Box>
      <DebouncedSearch onSearchChange={setSearchKeyword} 
      placeHolder={'Search for patients by name'}
      sx={sharedStyles?.mv1}
      />
      
      <Box className="ag-theme-quartz" sx={sharedStyles?.responsiveTableHeight}>
        <AgGridReact
          pagination={true}
          rowModelType={'infinite'}
          paginationPageSize={perPage}
          cacheBlockSize={perPage}
          onGridReady={onGridReady}
          rowHeight={60}
          columnDefs={columnDefs()}
          defaultColDef={{flex:1,minWidth:170}}
          overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
        />
      </Box>
    </Box>
  );
};

export default GridComponentPatients;
