import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
  ButtonGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { editEventModalStyles } from "./styles";
import { PRESET_DURATIONS } from '../../utils/staticData';
import TableHeader from "../../components/TableHeader";

const EditEventModal = ({ open, onClose, onEditEvent, event }) => {
  const [title, setTitle] = useState("");
  const [start, setStart] = useState(new Date());
  const [duration, setDuration] = useState(30);
  const [patientName, setPatientName] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [notes, setNotes] = useState("");
  const [showCustomDuration, setShowCustomDuration] = useState(false);
  const [customHours, setCustomHours] = useState(0);
  const [customMinutes, setCustomMinutes] = useState(0);

  useEffect(() => {
    if (event) {
      const titleParts = event?.title?.split(" - ");
      setTitle(titleParts?.[0] ?? "");
      setStart(event?.start ? new Date(event.start) : new Date());
      const eventDuration =
        event?.end && event?.start
          ? (new Date(event.end).getTime() - new Date(event.start).getTime()) /
            60000
          : 30;
      setDuration(eventDuration);
      setPatientName(event?.patientName ?? titleParts?.[1] ?? "");
      setNotes(event?.notes ?? "");
      setSelectedPatient({...event?.patient,id:event?.id} ?? null);
    }
  }, [event]);

  const end = useMemo(() => {
    return new Date(start?.getTime() + duration * 60000);
  }, [start, duration]);

  const handlePatientSelect = (patient) => {
    setSelectedPatient(patient);
    setPatientName(patient ? `${patient?.firstName} ${patient?.lastName}` : '');
  };

  const handleEditEvent = () => {
    onEditEvent?.({
      ...event,
      title: `${title} - ${patientName}`, 
      start,
      end,
      patientName,
      patientId: selectedPatient?.id,
      notes,
    });
    onClose?.();
  };

  const handlePresetDuration = (minutes) => {
    setDuration(minutes);
    setShowCustomDuration(false);
  };

  const handleCustomDuration = () => {
    setShowCustomDuration(true);
    setCustomHours(Math.floor(duration / 60));
    setCustomMinutes(duration % 60);
  };

  const applyCustomDuration = () => {
    const totalMinutes = customHours * 60 + customMinutes;
    setDuration(totalMinutes);
    setShowCustomDuration(false);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={editEventModalStyles?.modalBox}>
        <Box sx={editEventModalStyles?.headerBox}>
          <Typography variant="h5" component="h2">
            Edit Appointment
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={editEventModalStyles?.divider} />

        <TableHeader
          setFilterPatient={handlePatientSelect}
          idNeeded={true}
        />

        <TextField
          label="Patient Name"
          value={patientName}
          onChange={(e) => setPatientName(e?.target?.value ?? '')}
          sx={editEventModalStyles?.formField}
          disabled
        />
        <TextField
          label="Appointment Title"
          value={title}
          onChange={(e) => setTitle(e?.target?.value)}
          sx={editEventModalStyles?.formField}
        />
        <TextField
          label="Start Time"
          type="datetime-local"
          value={moment(start).format("YYYY-MM-DDTHH:mm")}
          onChange={(e) => setStart(new Date(e?.target?.value ?? new Date()))}
          sx={editEventModalStyles?.formField}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Time"
          type="datetime-local"
          value={moment(end).format("YYYY-MM-DDTHH:mm")}
          InputProps={{
            readOnly: true,
          }}
          sx={editEventModalStyles?.formField}
          InputLabelProps={{ shrink: true }}
        />
        {PRESET_DURATIONS?.length > 0 && (
          <ButtonGroup sx={editEventModalStyles?.buttonGroup}>
            {PRESET_DURATIONS.map((preset) => (
              <Button
                key={preset?.value}
                onClick={() => handlePresetDuration(preset?.value)}
                variant={
                  duration === preset?.value && !showCustomDuration
                    ? "contained"
                    : "outlined"
                }
                sx={editEventModalStyles?.durationButton}
                className={
                  duration === preset?.value && !showCustomDuration
                    ? "Mui-selected"
                    : ""
                }
              >
                {preset?.label}
              </Button>
            ))}
            <Button
              onClick={handleCustomDuration}
              variant={showCustomDuration ? "contained" : "outlined"}
              sx={editEventModalStyles?.durationButton}
              className={showCustomDuration ? "Mui-selected" : ""}
            >
              Custom
            </Button>
          </ButtonGroup>
        )}
        {showCustomDuration && (
          <Box sx={editEventModalStyles?.customDurationBox}>
            <TextField
              label="Hours"
              type="number"
              value={customHours}
              onChange={(e) =>
                setCustomHours(Math.max(0, parseInt(e?.target?.value) || 0))
              }
              InputProps={{
                inputProps: { min: 0 },
                sx: editEventModalStyles?.durationInput,
              }}
              sx={editEventModalStyles?.durationField}
            />
            <TextField
              label="Minutes"
              type="number"
              value={customMinutes}
              onChange={(e) =>
                setCustomMinutes(
                  Math.max(0, Math.min(59, parseInt(e?.target?.value) || 0))
                )
              }
              InputProps={{
                inputProps: { min: 0, max: 59 },
                sx: editEventModalStyles?.durationInput,
              }}
              sx={editEventModalStyles?.durationField}
            />
            <Button
              variant="contained"
              onClick={applyCustomDuration}
              sx={editEventModalStyles?.applyButton}
            >
              Apply
            </Button>
          </Box>
        )}
        <TextField
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target?.value)}
          multiline
          rows={3}
          sx={editEventModalStyles?.notesField}
        />
        <Button
          variant="contained"
          onClick={handleEditEvent}
          sx={editEventModalStyles?.updateButton}
        >
          Update Appointment
        </Button>
      </Box>
    </Modal>
  );
};

export default EditEventModal;