import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { styles } from './styles'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import { get_GetPatientDataById } from '../../services/addPatient'
import { paFormErrors, unitedStates } from '../../utils/staticData'
import TableHeader from '../../components/TableHeader'
import { sharedStyles } from '../../sharedStyles'
import AdvancedQuickSearch from '../../components/RadioButtons/AdvancedQuickSearch'
import CenteredSubmitCancel from '../../components/buttons/CenteredSubmitCancel'
import PageLoader from '../../components/loader/PageLoader'
import { useNavigate } from 'react-router-dom'

const DiscoverySearch = () => {
    const [errors, setErrors] = useState({});
    const [showAllFields, setShowAllFields] = useState(false);
    const [patientSelected, setPatientSelected] = useState(null);
    const [loaders, setLoaders] = useState({ form: false, patient: false });
    const [data, setData] = useState({
        dob: null,
        npi: JSON.parse(localStorage?.getItem('selectedNpi'))?.npiNumber || '',
    })

    const navigate = useNavigate()

    const handleDateChange = (date, field) => {
        setData({
            ...data,
            [field]: date,
        });
    };

    const handleSubmit = async () => {
        try {
            const { firstName, lastName, dob, state, npi} = data
            if (
                !firstName ||
                !lastName ||
                !dob ||
                !npi
            ) {
                setErrors(prev => {
                    return ({
                        ...prev,
                        firstName: firstName ? false : true,
                        lastName: lastName ? false : true,
                        dob: dob ? false : true,
                        npi: npi ? false : true
                    })
                })
                toast.error(paFormErrors?.propInfo)
                return
            }
            else {
                setErrors(prev => {
                    return ({
                        ...prev,
                        firstName: false,
                        lastName: false,
                        dob: false,
                        state: false,
                        npi: false
                    })
                })
                setLoaders(prev => ({ ...prev, form: true }))
                navigate('/rxResponse')
            }
        }
        catch (e) {
            console.log(e)
        }
        finally {
            setLoaders(prev => ({ ...prev, form: false }))
        }
    }

    const handleSelectedPatient = async () => {
        try {
            setLoaders(prev => ({ ...prev, patient: true }))
            const selectedPatientData = await get_GetPatientDataById(patientSelected?.id)
            if (selectedPatientData?.data) {
                const { firstName, lastName, dob, address, middleName } = selectedPatientData?.data
                setData(prev => {
                    return ({
                        ...prev,
                        firstName,
                        lastName,
                        dob: dayjs(dob),
                        state: address?.state ? unitedStates?.find(e => e?.name === address?.state)?.code : '',
                        middleName
                    })
                })
            }
        }
        catch (e) {
            console.log(e)
        }
        finally {
            setLoaders(prev => ({ ...prev, patient: false }))
        }
    }

    useEffect(() => {
        if (patientSelected?.id) {
            handleSelectedPatient()
        }
    }, [patientSelected])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Get Details for Patient's RX Benefits
                </Typography>
                <div style={sharedStyles?.mt10px}>
                    <TableHeader
                        setFilterPatient={setPatientSelected} idNeeded
                        handleCrossValue={() => setData((prev) => ({
                            ...prev,
                            firstName: '',
                            lastName: '',
                            middleName: '',
                            dob: null,
                            state: ''
                        }))}
                    />
                </div>

                {loaders?.patient ? (
                    <PageLoader />
                ) : (
                    <>
                        <AdvancedQuickSearch
                            value={showAllFields}
                            onChange={(e) => setShowAllFields(e.target.value === "true")}
                        />


                        <Box sx={styles?.commonPaperContainer}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={showAllFields ? 4 : 6}>
                                    <TextField
                                        fullWidth
                                        label={'First Name'}
                                        value={data?.firstName}
                                        onChange={(e) => { setData(prev => ({ ...prev, firstName: e?.target?.value })) }}
                                        required
                                        error={errors?.firstName}
                                    />
                                </Grid>
                                {showAllFields && <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        label={'Middle Name'}
                                        value={data?.middleName}
                                        onChange={(e) => { setData(prev => ({ ...prev, middleName: e?.target?.value })) }}

                                    />
                                </Grid>}
                                <Grid item xs={12} md={showAllFields ? 4 : 6}>
                                    <TextField
                                        fullWidth
                                        label={'Last Name'}
                                        value={data?.lastName}
                                        onChange={(e) => { setData(prev => ({ ...prev, lastName: e?.target?.value })) }}
                                        required
                                        error={errors?.lastName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={showAllFields ? 6 : 12}>
                                    <DatePicker
                                        label={'Date of Birth'}
                                        sx={errors?.dob ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                        value={data?.dob}
                                        onChange={(date) => handleDateChange(date, 'dob')}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                required: true,
                                                error: !!errors?.dob,
                                            },
                                        }}
                                    />
                                </Grid>
                                
                                {showAllFields &&
                                    <>
                                        <Grid item xs={12} md={6}>
                                          <FormControl fullWidth
                                          >
                                            <InputLabel>Patient's State</InputLabel>
                                            <Select
                                              value={data?.state}
                                              label="Patient's State"
                                              onChange={(e) => setData(prev => ({ ...prev, state: e?.target?.value }))}

                                            >
                                              {unitedStates?.map(({ name, code }) => (
                                                <MenuItem key={code} value={code}>
                                                  {name}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                label={'NPI'}
                                                value={data?.npi}
                                                onChange={(e) => { setData(prev => ({ ...prev, npi: e?.target?.value })) }}
                                                error={errors?.npi}

                                            />
                                        </Grid>
                                      

                                        <Grid item xs={12} md={6}>
                                            <DatePicker
                                                label={'Schedule Date'}
                                                sx={errors?.scheduleDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                                value={data?.scheduleDate}
                                                onChange={(date) => handleDateChange(date, 'scheduleDate')}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: !!errors['scheduleDate'],
                                                    },
                                                }}
                                            />
                                        </Grid>
                                      
                                    </>
                                }
                            </Grid>
                            <CenteredSubmitCancel
                                hideCancel
                                mt={1}
                                onClick={handleSubmit}
                                disabled={loaders?.form}
                                loading={loaders?.form}
                            />
                        </Box>
                    </>
                )}
            </Paper>
        </LocalizationProvider>
    )
}

export default DiscoverySearch