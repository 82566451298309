import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { styles } from './styles'
import DetailCard from './DetailCard'
import { capitalizedFirstLetter } from '../../../../utils/reusableFunctions'
import InfoRow from './InfoRow'
import { monthDyFormat } from '../../../../utils/DateFunctions'

const FormDetailsNew = ({ data, setSelectedTab, onSubmit, isLoading, title, tabNums }) => {

    return (
        <Container maxWidth="lg" sx={styles?.formDeatilsContainer}>
            <Box sx={styles?.formDeatilsMainBox}>
                <Typography
                    variant="h4"
                    sx={styles?.formDeatilsHeading}
                >
                    {`${title ?? ''}`}
                </Typography>
                {data?.patientInfo && (
                    <DetailCard title="Patient Details" onEdit={() => setSelectedTab(tabNums?.patient)}>
                        <InfoRow
                            label="Name"
                            value={`${capitalizedFirstLetter(data?.patientInfo?.firstName) || ''} ${capitalizedFirstLetter(data?.patientInfo?.lastName) || ''} ${data?.patientInfo.suffix?.toUpperCase() || ''}`}
                        />
                        <InfoRow label="Birth Date" value={monthDyFormat(data?.patientInfo.birthDate)} />
                        <InfoRow label="Gender" value={data?.patientInfo.genderCode} />
                        <InfoRow label="Relationship" value={data?.patientInfo?.subscriberRelationshipCode || '----'} />
                        {data?.patientInfo?.conditionCode && <InfoRow label="Condition" value={data?.patientInfo.conditionCode || '----'} />}
                        {data?.patientInfo?.statusCode && <InfoRow label="Status" value={data?.patientInfo.statusCode || '----'} />}
                    </DetailCard>
                )}
                {data?.subscriberInfo && (
                    <DetailCard title="Subscriber Information" onEdit={() => setSelectedTab(tabNums?.subscriberInfo)}>
                        <InfoRow
                            label="Name"
                            value={`${capitalizedFirstLetter(data?.subscriberInfo?.firstName) || ''} ${capitalizedFirstLetter(data?.subscriberInfo?.lastName) || ''} ${data?.subscriberInfo.suffix?.toUpperCase() || ''}`}
                        />
                        <InfoRow label="Member ID" value={data?.subscriberInfo.memberId} />
                    </DetailCard>
                )}
                {data?.diagnosesInfo && data?.diagnosesInfo?.length > 0 && (
                    <DetailCard title="Diagnoses" onEdit={() => setSelectedTab(tabNums?.diagnosesInfo)}>
                        {data?.diagnosesInfo.map((diag, index) => (
                            <Box key={diag.id || index} sx={{ mb: index !== data?.diagnosesInfo.length - 1 ? 2 : 0 }}>
                                <InfoRow label="ICD Code" value={diag?.icdCode} />
                                {diag?.qualifierCode && <InfoRow label="Diagnose's Qualifier Code" value={diag?.qualifierCode ?? '--'} />}
                                <InfoRow label="Description" value={diag?.fullDescription} />
                                <InfoRow label="Long Description" value={diag?.longDescription} />
                                {diag?.date && <InfoRow label="Cody Type" value={diag?.codyType} />}
                                {index !== data?.diagnose?.length - 1 && <p>---</p>}
                            </Box>
                        ))}
                    </DetailCard>
                )}
                {data?.procedureInfo && data?.procedureInfo.length > 0 && (
                    <DetailCard title="Procedures" onEdit={() => setSelectedTab(tabNums?.proceduresInfo)}>
                        {data?.procedureInfo?.map((proc, index) => (
                            <Box key={proc.id || index} sx={{ mb: index !== data?.procedureInfo.length - 1 ? 2 : 0 }}>
                                <InfoRow label="CPT Code" value={proc.CptCode} />
                                {proc?.qualifierCode && <InfoRow label="Procedure's Qualifier Code" value={proc?.qualifierCode ?? '--'} />}
                                <InfoRow label="Description" value={proc.fullDescription} />
                                <InfoRow label="Long Description" value={proc?.longDescription ?? '--'} />
                                {proc?.shortDescription && <InfoRow label="Short Description" value={proc?.shortDescription ?? '--'} />}
                                {proc?.fromDate && <InfoRow label="From Date" value={monthDyFormat(proc?.fromDate) ?? '--'} />}
                                {proc?.toDate && <InfoRow label="To Date" value={monthDyFormat(proc?.toDate) ?? '--'} />}
                                {proc?.quantity && <InfoRow label="Quantity" value={proc?.quantity ?? '--'} />}
                                {proc?.quantityTypeCode && <InfoRow label="Quantity Type" value={proc?.quantityTypeCode ?? '--'} />}
                                {proc?.timePeriodQuantity && <InfoRow label="Time Period Quantity" value={proc?.timePeriodQuantity ?? '--'} />}
                                {proc?.timePeriodQuantityQualifier && <InfoRow label="Time Period Quantity Qualifier" value={proc?.timePeriodQuantityQualifier ?? '--'} />}
                                {proc?.timeframeFrequency && <InfoRow label="Time Frame Quantity" value={proc?.timeframeFrequency ?? '--'} />}
                                {proc?.timeFrameFrequencyQualifier && <InfoRow label="Time Frame Quantity Qualifier" value={proc?.timeFrameFrequencyQualifier ?? '--'} />}

                                {index !== data?.procedure?.length - 1 && <p>---</p>}
                            </Box>
                        ))}
                    </DetailCard>
                )}
                {
                    data?.admissionInfo && (
                        <DetailCard title="Admission Information" onEdit={() => setSelectedTab(tabNums?.admissionInfo)}>
                            <Box>
                                <InfoRow label="Admission Date" value={monthDyFormat(data?.admissionInfo?.admissionDate)} />
                                <InfoRow label="Admission Type" value={data?.admissionInfo?.admissionType} />
                                <InfoRow label="Admission Source" value={data?.admissionInfo?.admissionSourceCode} />
                                <InfoRow label="Request Type" value={data?.admissionInfo?.requestTypeCode} />
                                <InfoRow label="Quantity Type" value={data?.admissionInfo?.quantityTypeCode} />
                                <InfoRow label="Quanitity" value={data?.admissionInfo?.quantity} />
                            </Box>
                        </DetailCard>
                    )
                }
                {
                    data?.providerNotes && data?.providerNotes.length > 0 && (
                        <DetailCard title="Provider Notes" onEdit={() => setSelectedTab(tabNums?.providerNotes)}>
                            {data?.providerNotes.map((note, index) => (
                                <Typography
                                    sx={styles?.formDetailsProviderNotes}
                                >
                                    {capitalizedFirstLetter(note || '--')}
                                </Typography>
                            ))}
                        </DetailCard>
                    )
                }
                {data?.renderingProviders?.length &&
                    data?.renderingProviders?.map(elem => {
                        return (
                            <DetailCard title="Rendering Provider" onEdit={() => setSelectedTab(tabNums?.renderingProviders)}>
                                <InfoRow
                                    label="Name"
                                    value={`${capitalizedFirstLetter(elem?.firstName) || '-----'} ${capitalizedFirstLetter(elem?.lastName) || '----'}`}
                                />
                                <InfoRow
                                    label="Provider Type"
                                    value={`${elem?.roleCode}`}
                                />
                                <InfoRow label="NPI" value={elem?.npi} />
                                <InfoRow label="Phone" value={elem?.phone} />
                                <InfoRow
                                    label="Address"
                                    value={`${elem?.addressLine1 || '----'} ${elem?.addressLine2 || '----'}`}
                                />
                                <InfoRow
                                    label="Location"
                                    value={`${capitalizedFirstLetter(elem?.city) || '----'} ${elem?.stateCode ? `,${elem?.stateCode}` : ''} ${elem?.zipCode || '----'}`}
                                />
                            </DetailCard>
                        )
                    }
                    )}
     
                            <DetailCard title="Requesting Provider" onEdit={() => setSelectedTab(tabNums?.renderingProviders)}>
                                <InfoRow
                                    label="Name"
                                    value={`${capitalizedFirstLetter(data?.requestingProvider?.firstName) || '-----'} ${capitalizedFirstLetter(data?.requestingProvider?.lastName) || '----'}`}
                                />
                                <InfoRow
                                    label="Contact Name"
                                    value={`${capitalizedFirstLetter(data?.requestingProvider?.contactName) || '-----'}`}
                                />
                                <InfoRow
                                    label="Fax"
                                    value={`${data?.requestingProvider?.fax}`}
                                />
                                <InfoRow label="NPI" value={data?.requestingProvider?.npi} />
                                <InfoRow label="Phone" value={data?.requestingProvider?.phone} />
                                <InfoRow
                                    label="Role Code"
                                    value={`${data?.requestingProvider?.roleCode}}`}
                                />
                            </DetailCard>
                  

            </Box>
        </Container>
    )
}

export default FormDetailsNew
