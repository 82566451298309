
import React, { useContext } from 'react'
import { FloridaBlueArContext,  FloridaBlueArContextProvider } from '../../../../../context/priorAuthorizationForms/FloridaBlueArContext';
import ServicesTypeFloridaBlueArForm from './ServicesTypeFloridaBlueArForm';
import PatientFloridaBlueArForm from './PatientFloridaBlueArForm';
import DatesInfoBcbsIllinoisHsForm from './DatesInfoFloridaBlueArForm';
import DiagnosesFloridaBlueArForm from './DiagnosesFloridaBlueArForm';
import ProceduresFloridaBlueArForm from './ProceduresFloridaBlueArForm';

const FloridaBlueArForms = ({title}) => {
    return (
        <FloridaBlueArContextProvider>
            <AllForms  title={title}/>
        </FloridaBlueArContextProvider>
    )
}

export default FloridaBlueArForms

const AllForms = ({title}) => {
    const {
        selectedTab,
        
    } = useContext(FloridaBlueArContext)
    const forms = [
        <ServicesTypeFloridaBlueArForm/>,
        <PatientFloridaBlueArForm/>,
        <DatesInfoBcbsIllinoisHsForm/>,
        <DiagnosesFloridaBlueArForm/>,
        <ProceduresFloridaBlueArForm/>,
    ]
    return forms[selectedTab] || null;
};