import React, { useContext, useState } from 'react'
import { MercyGroupContext } from '../../../../../context/priorAuthorizationForms/MercyCareContext'
import DefaultDatesInfo from '../../components/MercyCare/DefaultDatesInfo';

const MercyCareDatesInfo = () => {
    const {datesInfo,setDatesInfo,setSelectedTab} = useContext(MercyGroupContext)
    const [dateLimits] = useState({max:'2025-06-23',min:'2024-06-27'});

  return (
    <DefaultDatesInfo
    dateLimits={dateLimits}
    formData={datesInfo}
    setFormData={setDatesInfo}
    setSelectedTab={setSelectedTab}
    />
  )
}

export default MercyCareDatesInfo