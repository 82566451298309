import React from 'react'
import { 
    Box, 
    Typography, 
    Grid, 
    Divider, 
    Card, 
    CardContent, 
    CardHeader,
    Chip,
    List,
    ListItem,
    ListItemText,
    Avatar
  } from '@mui/material';
import { colors } from '../../utils/colors';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import { numericDateFormat } from '../../utils/DateFunctions';
import { sharedStyles } from '../../sharedStyles';
import { styles } from './styles';

const RxPatientDetails = ({patient}) => {
  return (
    <Grid item xs={12} md={6}>
    <Card sx={sharedStyles?.fullHeight}>
      <CardHeader 
        avatar={<Avatar sx={{ bgcolor: colors?.muiThemeBlue }}><PersonIcon /></Avatar>}
        title="Patient Information" 
        sx={sharedStyles?.commonBottomBorder}
      />
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">
            {patient?.Name?.FirstName} {patient?.Name?.LastName}
          </Typography>
          <Chip 
            label={patient?.Gender === 0 ? "Male" : "Female"} 
            size="small" 
            sx={styles?.patientChip}
          />
        </Box>
        
        <List>
          <ListItem sx={styles?.patientListItem}>
            <CalendarTodayIcon sx={styles?.patientIcons} />
            <ListItemText 
              primary="Date of Birth" 
              secondary={numericDateFormat(patient?.DateOfBirth)} 
            />
          </ListItem>
          
          <Divider component="li" />
          
          <ListItem sx={styles?.patientListItem}>
            <LocationOnIcon sx={styles?.patientIcons} />
            <ListItemText 
              primary="Address" 
              secondary={
                <>
                  {patient?.Address?.AddressLine1}
                  {patient?.Address?.AddressLine2 && <>, {patient?.Address?.AddressLine2}</>}<br />
                  {patient?.Address?.City}, {patient?.Address?.StateProvince} {patient?.Address?.PostalCode}
                </>
              } 
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  </Grid>
  )
}

export default RxPatientDetails