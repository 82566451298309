import { createContext, useEffect, useState } from "react";
import { aetnaDefaultData, defaultPatientSmplyHs, hsSubscriberDefaultData, smplHsServiceDefault, smplyHsRpDefault, } from "../../utils/staticData";



const FloridaBlueArContext = createContext();


const FloridaBlueArContextProvider = ({ children }) => {

    const defaultRenderingProvider = [{
        fax:'',
        npi:'',
        city:'',
        taxId:'',
        zipCode:'',
        lastName:'',
        roleCode:'',
        firstName:'',
        stateCode:'',
        addressLine1:'',
        addressLine2:'',
        payerAssignedProviderId:'',

     }]

    const [selectedTab, setSelectedTab] = useState(0)
    const [patient, setPatient] = useState({})
    const [datesInfo, setDatesInfo] = useState({});
    const [onceCompleted, setOnceCompleted] = useState(false)
    const [diagnosesInfo, setDiagnosesInfo] = useState([])
    const [proceduresInfo, setProceduresInfo] = useState([])
    const [subscriber, setSubscriber] = useState({})
    const [providerNotes, setProviderNotes] = useState('')
    const [serviceInfo, setServiceInfo] = useState({})
    const [requestingProvider, setRequestingProvider] = useState({})
    const [renderingProviders, setRenderingProviders] = useState(defaultRenderingProvider)
    const [admissionInfo, setAdmissionInfo] = useState('')

    useEffect(()=>{
    setProceduresInfo(proceduresInfo?.map(e=> ({...e,quantityTypeCode:''})))
    },[serviceInfo])


    return (
        <FloridaBlueArContext.Provider value={{
            selectedTab, setSelectedTab,
            patient, setPatient,
            datesInfo, setDatesInfo,
            onceCompleted, setOnceCompleted,
            diagnosesInfo, setDiagnosesInfo,
            proceduresInfo, setProceduresInfo,
            subscriber, setSubscriber,
            providerNotes, setProviderNotes,
            serviceInfo, setServiceInfo,
            requestingProvider, setRequestingProvider,
            renderingProviders, setRenderingProviders,
            admissionInfo, setAdmissionInfo,
            defaultRenderingProvider
        }}>
            {children}
        </FloridaBlueArContext.Provider>
    )
};


export { FloridaBlueArContextProvider, FloridaBlueArContext };