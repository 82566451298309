import { Card, Chip, Paper, styled, Typography } from '@mui/material';
import { colors } from '../../utils/colors';


export const HeaderSection = styled(Paper)(() => ({
    padding: '24px',
    marginBottom: '24px',
    backgroundColor: colors?.tableGray
}));

export const InfoCard = styled(Card)(() => ({
    height: '100%',
    boxShadow: `0 4px 8px ${colors?.defaultBoxShadow}`,
}));

export const SectionTitle = styled(Typography)(() => ({
    fontWeight: 600,
    marginBottom: '16px',
    color: colors?.themeBlue,
}));

export const InfoLabel = styled(Typography)(() => ({
    fontWeight: 500,
    color: colors?.mediumGrayText,
    marginBottom: '4px',
}));

export const InfoValue = styled(Typography)(() => ({
    marginBottom: '16px',
}));

export const StatusChip = styled(Chip)(() => ({
    fontWeight: 500,
    padding: '0 8px',
}));
