import { useContext } from "react"
import { MercyGroupContext, MercyGroupContextProvider } from "../../../../../context/priorAuthorizationForms/MercyCareContext";
import MeryCarePatientInfo from "./MeryCarePatientInfo";
import MercyCareSubscriberInfo from "./MercyCareSubscriberInfo";
import MercyCareDatesInfo from "./MercyCareDatesInfo";
import MercyCareProviderNotes from "./MercyCareProviderNotes";
import MercyCareAdditionalInfo from "./MercyCareAdditionalInfo";
import MercyCareDefaultRenderingProviders from "./MercyCareDefaultRenderingProviders";
import MeryCareReuquestingProvider from "./MeryCareReuquestingProvider";
import FormDetailsMercyCare from "../../components/FormDetailsMercyCare";
import MercyCareProcedures from "./MercyCareProcedures";

 const MercycareForms = () => {
 return(
    <MercyGroupContextProvider>
          <AllMercyCareForms/>
    </MercyGroupContextProvider>
 )
}

const AllMercyCareForms = () => {
  const {selectedTab} = useContext(MercyGroupContext);

  const forms = [
  <MeryCarePatientInfo />,
  <MercyCareSubscriberInfo/>,
  <MercyCareDatesInfo />,
  <MercyCareProviderNotes/>,
  <MercyCareAdditionalInfo />,
  <MercyCareDefaultRenderingProviders />,
  <MeryCareReuquestingProvider/>,
  <MercyCareProcedures />
]

    return forms[selectedTab] || null
}

export default MercycareForms;