import { Box, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useState } from 'react'
import { sharedStyles } from '../../../../../sharedStyles'
import { styles } from '../styles'
import { defaultMercyCarePayload, UnicarePayload } from '../../../../../utils/staticData'
import UnicareNextBtn from '../../UnicareNextBtn'
import toast from 'react-hot-toast'
import { postalCodeFormat } from '../../../../../utils/valueFormatter/Formats'


const DefaultRenderingProviders = ({ onceCompleted, setSelectedTab, renderingProvider, setRenderingProvider, selectedTab }) => {
    const [showAllFields, setShowAllFields] = useState(false);
    const [roleCode,setRoleCode] = useState("");
    const [err, setErr] = useState(false)


    const handleChange = (e) => {
        const { name, value } = e.target;
    
        if(name === 'roleCode') {
                setRoleCode(value?.code)
                setRenderingProvider(prevState => {
                    return {
                        ...prevState,
                        [name]: value?.value
                    }
                })
                return;
            } else if(name === 'zipCode') {
                   let formattedValue = postalCodeFormat(value);
                  
                     setRenderingProvider(prevState => {
                          return {
                            ...prevState,
                            [name]: formattedValue
                          }
                     });
                     return;
            } else if(name === 'fax') {
                    const numericValue = value.replace(/[^0-9]/g, '');
                    setRenderingProvider(prevState => ({
                        ...prevState,
                        [name]: numericValue
                    }));
                    return;
                } else if(name === 'payerAssignedProviderId') {
                    const regex = /^[a-zA-Z0-9]{2,50}$/;
                    if(!regex.test(value)) {
                        return;
                    } else {
                        setRenderingProvider(prevState => ({
                            ...prevState,
                            [name]: value
                        }));
                        return;
                    }
                }
            
            
            setRenderingProvider(prevState => {
                return {
                    ...prevState,
                    [name]: value
                }
            })
    }
  
    const handleSubmit = () => {
        // Check if all required fields (except firstName) are filled
        if (
            !renderingProvider?.roleCode
            || !renderingProvider?.lastName
            || !renderingProvider?.city
            || !renderingProvider?.stateCode
            || !renderingProvider?.addressLine1
            || !renderingProvider?.zipCode
        ) {
         
            setErr(true);
            toast.error('Please fill all the required fields');
            return;
        }
    
        // Proceed with form submission logic if everything is valid
        console.log('Form submitted successfully');
        setSelectedTab(prev => prev + 1)
    }

   const isFirstNameAllowed = () => {
        return defaultMercyCarePayload?.renderingProviders?.elements?.firstName?.notAllowedWhen?.roleCode?.containedIn?.some((item) => item === roleCode);
    }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Paper elevation={3} sx={styles?.parentPaperContainer}>
        <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
            Rendering Providers Information
        </Typography>
        <RadioGroup
            value={showAllFields}
            onClick={(event) => setShowAllFields(!showAllFields)}
        >
            <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
        </RadioGroup>


        <Box sx={styles?.commonPaperContainer}>
            <Grid container spacing={3}>
                <Grid item xs={11}>
                    <Typography variant="h6">Rendering Provider</Typography>
                </Grid>
                {/* <Grid item xs={1}>
                        <IconButton
                            onClick={() => removeProcedure(index)}
                            disabled={renderingProvider?.length === 1}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid> */}


                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel>{UnicarePayload?.renderingProviders?.elements?.roleCode?.label}*</InputLabel>
                        <Select
                            name={'roleCode'}
                            value={renderingProvider?.roleCode}
                            label={UnicarePayload?.renderingProviders?.elements?.roleCode?.label}
                            onChange={(e) => handleChange({
                                ...e,
                                target: {
                                    ...e.target,
                                    value: UnicarePayload?.renderingProviders?.elements?.roleCode?.values.find(
                                        elem => elem.value === e.target.value
                                    ) || {} 
                                }
                            })}
                            error={UnicarePayload?.renderingProviders?.elements?.roleCode?.required && !renderingProvider?.roleCode && err}
                        >
                            {UnicarePayload?.renderingProviders?.elements?.roleCode?.values.map(elem => {
                                return (
                                    <MenuItem value={elem?.value} key={elem?.code}>
                                        {elem?.value}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        <FormHelperText>
                            {UnicarePayload?.renderingProviders?.elements?.roleCode?.required && !renderingProvider?.roleCode && err ? UnicarePayload?.renderingProviders?.elements?.roleCode?.errorMessage : 'Required *'}
                        </FormHelperText>
                    </FormControl>
                </Grid>


                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label={UnicarePayload?.renderingProviders?.elements?.lastName?.label}
                        value={renderingProvider?.lastName}
                        onChange={handleChange}
                        name='lastName'
                        inputProps={{ maxLength: UnicarePayload?.renderingProviders?.elements?.lastName?.maxLength }}
                        error={UnicarePayload?.renderingProviders?.elements?.lastName?.required && !renderingProvider?.lastName && err}
                        helperText={UnicarePayload?.renderingProviders?.elements?.lastName?.required && !renderingProvider?.lastName && err ? 'Last Name is required' : 'Required *'}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label={UnicarePayload?.renderingProviders?.elements?.city?.label}
                        value={renderingProvider?.city}
                        name='city'
                        onChange={handleChange}
                        error={UnicarePayload?.renderingProviders?.elements?.city?.required && !renderingProvider?.city && err}
                        helperText={UnicarePayload?.renderingProviders?.elements?.city?.required && !renderingProvider?.city && err ? UnicarePayload?.renderingProviders?.elements?.city?.errorMessage : 'Required *'}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel>Rendering Provider State</InputLabel>
                        <Select
                            name={'stateCode'}
                            value={renderingProvider?.stateCode}
                            label={UnicarePayload?.renderingProviders?.elements?.stateCode?.label}
                            onChange={handleChange}
                            error={UnicarePayload?.renderingProviders?.elements?.stateCode?.required && !renderingProvider?.stateCode && err}
                        >
                            {UnicarePayload?.renderingProviders?.elements?.stateCode?.values?.map(({ value, code }) => (
                                <MenuItem key={value} value={code}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                            {UnicarePayload?.renderingProviders?.elements?.stateCode?.required && !renderingProvider?.stateCode && err ? UnicarePayload?.renderingProviders?.elements?.stateCode?.errorMessage : 'Required *'}
                        </FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        name='addressLine1'
                        label={UnicarePayload?.renderingProviders?.elements?.addressLine1?.label}
                        value={renderingProvider?.addressLine1}
                        onChange={handleChange}
                        error={UnicarePayload?.renderingProviders?.elements?.addressLine1?.required && !renderingProvider?.addressLine1 && err}
                        helperText={UnicarePayload?.renderingProviders?.elements?.addressLine1?.required && !renderingProvider?.addressLine1 && err ? UnicarePayload?.renderingProviders?.elements?.addressLine1?.errorMessage : 'Required *'}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        name='zipCode'
                        label={UnicarePayload?.renderingProviders?.elements?.zipCode?.label}
                        value={renderingProvider?.zipCode}
                        onChange={handleChange}
                        error={UnicarePayload?.renderingProviders?.elements?.zipCode?.required && !renderingProvider?.zipCode && err}
                        inputProps={{ maxLength: UnicarePayload?.renderingProviders?.elements?.zipCode?.maxLength }}
                        helperText={UnicarePayload?.renderingProviders?.elements?.zipCode?.required && !renderingProvider?.zipCode && err ? UnicarePayload?.renderingProviders?.elements?.zipCode?.errorMessage : 'Required *'}
                    />
                </Grid>

                {
                 showAllFields &&  isFirstNameAllowed() ?
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                name='firstName'
                                label={UnicarePayload?.renderingProviders?.elements?.firstName?.label}
                                value={renderingProvider?.firstName}
                                onChange={handleChange}
                                inputProps={{ maxLength: UnicarePayload?.renderingProviders?.elements?.firstName?.maxLength }}
                                error={isFirstNameAllowed() && !renderingProvider?.firstName && err}
                                helperText={isFirstNameAllowed() && !renderingProvider?.firstName && err ? UnicarePayload?.renderingProviders?.elements?.firstName?.errorMessage : 'Required *'}
                            />
                        </Grid> :
                         null
                }


                {showAllFields &&
                    <>

                        {/* <Grid item xs={12} md={6}>
                                <FormControl fullWidth error={errors?.[index]?.roleCode}>
                                    <InputLabel>{UnicarePayload?.renderingProviders?.elements?.specialtyCode?.label}*</InputLabel>
                                    <Select
                                        value={renderingProvider.specialtyCode}
                                        label={UnicarePayload?.renderingProviders?.elements?.specialtyCode?.label}
                                        onChange={handleChange}
                                    >
                                        {UnicarePayload?.renderingProviders?.elements?.specialtyCode?.values.map(elem => {
                                            return (
                                                <MenuItem value={elem?.value} key={elem?.code}>
                                                    {elem?.value}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText sx={sharedStyles?.errorText}>
                                        {UnicarePayload?.renderingProviders?.elements?.roleCode?.required && !renderingProvider?.roleCode && err ? UnicarePayload?.renderingProviders?.elements?.roleCode?.errorMessage : 'Required *'}
                                    </FormHelperText>
                                </FormControl>
                            </Grid> */}


                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={UnicarePayload?.renderingProviders?.elements?.payerAssignedProviderId?.label}
                                value={renderingProvider?.payerAssignedProviderId}
                                onChange={handleChange}
                                name='payerAssignedProviderId'
                                inputProps={{ maxLength: UnicarePayload?.renderingProviders?.elements?.payerAssignedProviderId?.maxLength }}
                            />
                        </Grid>



                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                name='taxId'
                                label={UnicarePayload?.renderingProviders?.elements?.taxId?.label}
                                value={renderingProvider?.taxId}
                                onChange={handleChange}
                                inputProps={{ maxLength: UnicarePayload?.renderingProviders?.elements?.taxId?.maxLength }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={UnicarePayload?.renderingProviders?.elements?.fax?.label}
                                value={renderingProvider?.fax}
                                onChange={handleChange}
                                name='fax'
                                inputProps={{ maxLength: UnicarePayload?.renderingProviders?.elements?.fax?.maxLength }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                name='npi'
                                label={UnicarePayload?.renderingProviders?.elements?.npi?.label}
                                value={renderingProvider?.npi}
                                onChange={handleChange}
                                inputProps={{ maxLength: UnicarePayload?.renderingProviders?.elements?.npi?.maxLength }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                name='addressLine2'
                                label={UnicarePayload?.renderingProviders?.elements?.addressLine2?.label}
                                value={renderingProvider?.addressLine2}
                                onChange={handleChange}
                                inputProps={{ maxLength: UnicarePayload?.renderingProviders?.elements?.addressLine2?.maxLength }}
                            />
                        </Grid>


                    </>
                }

            </Grid>
        </Box>


        {/* <Box sx={{ mb: 4 }}>
            <IconButton color="primary">
                <AddIcon /> Add Provider
            </IconButton>
        </Box> */}
        <UnicareNextBtn handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
    </Paper>
</LocalizationProvider>
  )
}

export default DefaultRenderingProviders