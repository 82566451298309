import { createContext, useState } from "react";
import { UnicarePayload } from "../../utils/staticData";
import { convertElementsToObject } from "../../utils/reusableFunctions";



const UnicareContext = createContext();


const UNICARECONTEXTProvider = ({ children }) => {
const [patientInfo, setPatientInfo] = useState(convertElementsToObject(UnicarePayload?.patientInfo?.elements));
const [subscriberInfo, setSubscriberInfo] = useState(convertElementsToObject(UnicarePayload?.subscriber?.elements));
const [providerNotes, setProviderNotes] = useState([]);
const [renderingProvider, setRenderingProvider] = useState(convertElementsToObject(UnicarePayload?.renderingProviders?.elements));
const [requestingProvider, setRequestingProvider] = useState(convertElementsToObject(UnicarePayload?.requestingProvider?.elements));
const [datesInfo, setDatesInfo] = useState({fromDate: null, toDate: null});
const [additionalInfo, setAdditionalInfo] = useState(convertElementsToObject(UnicarePayload?.additionalInfo?.elements));
const [procedureInfo, setProcedureInfo] = useState([]);
const [diagnosisInfo, setDiagnosesInfo] = useState([]);
const [selectedTab, setSelectedTab] = useState(0);
const [onceCompleted, setOnceCompleted] = useState(false)


    
    return (
        <UnicareContext.Provider value={{
            patientInfo, setPatientInfo, selectedTab, setSelectedTab, diagnosisInfo, setDiagnosesInfo, onceCompleted,datesInfo, setProcedureInfo, procedureInfo,setDatesInfo, additionalInfo,setAdditionalInfo, setOnceCompleted, subscriberInfo, setSubscriberInfo, providerNotes, setProviderNotes, renderingProvider, setRenderingProvider, requestingProvider, setRequestingProvider
        }}>
            {children}
        </UnicareContext.Provider>
    )
}

export { UnicareContext, UNICARECONTEXTProvider };