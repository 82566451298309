import React from 'react'
import { 
    Box, 
    Typography, 
    Paper, 
    Grid, 
    Card, 
    CardContent, 
    CardHeader,
    Chip,
    Avatar
  } from '@mui/material';

  import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import { colors } from '../../utils/colors';
import { sharedStyles } from '../../sharedStyles';
import { styles } from './styles';

const RxMedicationDetails = ({medication}) => {
    const pharmacy = medication?.PatientSpecificPricingCoverages?.[0].Pharmacy
    const product = medication?.MedicationRequested?.DrugCoded?.ProductCode

    const pharamcyInfo = [
        `Type: ${pharmacy?.PharmacyType}`,
        `NCPDPID: ${pharmacy?.Identification.NCPDPID}`,
        `NPI: ${pharmacy?.Identification.NPI}`
    ]

  return (
    <Grid item xs={12}>
              <Card>
                <CardHeader 
                  avatar={<Avatar sx={{bgcolor:colors?.orange}}><LocalPharmacyIcon /></Avatar>}
                  title="Medication Information" 
                  sx={sharedStyles?.commonBottomBorder}
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Paper elevation={0} sx={styles?.medicationPaper}>
                        <Typography variant="subtitle2" color="text.secondary">Product Code</Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {product?.Code}
                        </Typography>
                        <Typography variant="caption">
                          Qualifier: {product?.Qualifier}
                        </Typography>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4}>
                      <Paper elevation={0} sx={styles?.medicationPaper}>
                        <Typography variant="subtitle2" color="text.secondary">Quantity & Supply</Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {medication?.QuantityPriced?.Value} units
                        </Typography>
                        <Typography variant="caption">
                          {medication?.DaysSupplyPriced} days supply
                        </Typography>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                      <Paper elevation={0} sx={styles?.medicationPaper}>
                        <Typography variant="subtitle2" color="text.secondary">Status & Cost</Typography>
                        <Typography variant="body1" fontWeight="medium" color="error.main">
                          {medication?.DrugStatus?.DrugStatusCode}
                        </Typography>
                        <Typography variant="caption">
                          Est. Patient Cost: ${medication?.EstimatedPatientPayAmount}
                        </Typography>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Pharmacy Information
                      </Typography>
                          <Box sx={styles?.pharmacyInfoChip}>
                              {
                                  pharamcyInfo?.map(elem => {
                                      return (
                                          <Chip
                                              label={elem}
                                              size="small"
                                          />
                                      )
                                  })
                              }
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
  )
}

export default RxMedicationDetails