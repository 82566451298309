import { Box, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { styles } from '../styles'
import { sharedStyles } from '../../../../../sharedStyles'
import { UnicarePayload } from '../../../../../utils/staticData'
import UnicareNextBtn from '../../UnicareNextBtn'

const DefaultAdditionalInfoForm = ({additionalInfo, setAdditionalInfo, setSelectedTab, selectedTab}) => {
    const [showAllFields, setShowAllFields] = useState(false);
    const [err, setErr] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setAdditionalInfo((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleSubmit = () => {
        console.log('submitted...');
        setSelectedTab(prev => prev + 1)
    }

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
               Additional Information
            </Typography>
            <RadioGroup
                value={showAllFields}
                onClick={(event) => setShowAllFields(!showAllFields)}
            >
                <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
            </RadioGroup>
            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>{UnicarePayload?.additionalInfo?.elements?.admissionSourceCode?.label}*</InputLabel>
                            <Select
                                disabled
                                name={'admissionSourceCode'}
                                value={additionalInfo?.admissionSourceCode?.value}
                                label={UnicarePayload?.additionalInfo?.elements?.admissionSourceCode?.label}
                                error={UnicarePayload?.additionalInfo?.elements?.admissionSourceCode?.required && !additionalInfo?.admissionSourceCode && err}
                                onChange={(e) => handleChange({
                                    ...e,
                                    target: {
                                        ...e.target,
                                        value: UnicarePayload?.additionalInfo?.elements?.admissionSourceCode?.values.find(
                                            elem => elem.value === e.target.value
                                        ) || {}
                                    }
                                })}
                            >
                                {UnicarePayload?.additionalInfo?.elements?.admissionSourceCode?.values?.map(elem => {
                                    return (
                                        <MenuItem value={elem?.value} key={elem?.code}>
                                            {elem?.value}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>
                                {UnicarePayload?.additionalInfo?.elements?.admissionSourceCode?.required && !additionalInfo?.admissionSourceCode && err ? UnicarePayload?.additionalInfo?.elements?.admissionSourceCode?.errorMessage : 'Values Not Avaialble'}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>{UnicarePayload?.additionalInfo?.elements?.admissionTypeCode?.label}*</InputLabel>
                            <Select
                                name={'admissionTypeCode'}
                                value={additionalInfo?.admissionTypeCode?.value}
                                label={UnicarePayload?.additionalInfo?.elements?.admissionTypeCode?.label}
                                error={UnicarePayload?.additionalInfo?.elements?.admissionTypeCode?.required && !additionalInfo?.admissionTypeCode && err}
                                onChange={(e) => handleChange({
                                    ...e,
                                    target: {
                                        ...e.target,
                                        value: UnicarePayload?.additionalInfo?.elements?.admissionTypeCode?.values?.find(
                                            elem => elem.value === e.target.value
                                        ) || {}
                                    }
                                })}
                            >
                                {UnicarePayload?.additionalInfo?.elements?.admissionTypeCode?.values?.map(elem => {
                                    return (
                                        <MenuItem value={elem?.value} key={elem?.code}>
                                            {elem?.value}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>
                                {UnicarePayload?.additionalInfo?.elements?.admissionTypeCode?.required && !additionalInfo?.admissionTypeCode && err ? UnicarePayload?.additionalInfo?.elements?.admissionTypeCode?.errorMessage : 'Required *'}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>{UnicarePayload?.additionalInfo?.elements?.quantityTypeCode?.label}*</InputLabel>
                            <Select
                                name={'quantityTypeCode'}
                                value={additionalInfo?.quantityTypeCode?.value}
                                label={UnicarePayload?.additionalInfo?.elements?.quantityTypeCode?.label}
                                error={UnicarePayload?.additionalInfo?.elements?.quantityTypeCode?.required && !additionalInfo?.quantityTypeCode && err}
                                onChange={(e) => handleChange({
                                    ...e,
                                    target: {
                                        ...e.target,
                                        value: UnicarePayload?.additionalInfo?.elements?.quantityTypeCode?.values?.find(
                                            elem => elem.value === e.target.value
                                        ) || {}
                                    }
                                })}
                            >
                                {UnicarePayload?.additionalInfo?.elements?.quantityTypeCode?.values?.map(elem => {
                                    return (
                                        <MenuItem value={elem?.value} key={elem?.code}>
                                            {elem?.value}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>
                                {UnicarePayload?.additionalInfo?.elements?.quantityTypeCode?.required && !additionalInfo?.quantityTypeCode && err ? UnicarePayload?.additionalInfo?.elements?.quantityTypeCode?.errorMessage : 'Required *'}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>{UnicarePayload?.additionalInfo?.elements?.serviceTypeCode?.label}*</InputLabel>
                            <Select
                                name={'serviceTypeCode'}
                                value={additionalInfo?.serviceTypeCode?.value}
                                label={UnicarePayload?.additionalInfo?.elements?.serviceTypeCode?.label}
                                error={UnicarePayload?.additionalInfo?.elements?.serviceTypeCode?.required && !additionalInfo?.serviceTypeCode && err}
                                onChange={(e) => handleChange({
                                    ...e,
                                    target: {
                                        ...e.target,
                                        value: UnicarePayload?.additionalInfo?.elements?.serviceTypeCode?.values?.find(
                                            elem => elem.value === e.target.value
                                        ) || {}
                                    }
                                })}
                            >
                                {UnicarePayload?.additionalInfo?.elements?.serviceTypeCode?.values?.map(elem => {
                                    return (
                                        <MenuItem value={elem?.value} key={elem?.code}>
                                            {elem?.value}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>
                                {UnicarePayload?.additionalInfo?.elements?.serviceTypeCode?.required && !additionalInfo?.serviceTypeCode && err ? UnicarePayload?.additionalInfo?.elements?.serviceTypeCode?.errorMessage : 'Required *'}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>{UnicarePayload?.additionalInfo?.elements?.requestTypeCode?.label}*</InputLabel>
                            <Select
                                name={'requestTypeCode'}
                                value={additionalInfo?.requestTypeCode?.value}
                                label={UnicarePayload?.additionalInfo?.elements?.requestTypeCode?.label}
                                error={UnicarePayload?.additionalInfo?.elements?.requestTypeCode?.required && !additionalInfo?.requestTypeCode && err}
                                onChange={(e) => handleChange({
                                    ...e,
                                    target: {
                                        ...e.target,
                                        value: UnicarePayload?.additionalInfo?.elements?.requestTypeCode?.values?.find(
                                            elem => elem.value === e.target.value
                                        ) || {}
                                    }
                                })}
                            >
                                {UnicarePayload?.additionalInfo?.elements?.requestTypeCode?.values?.map(elem => {
                                    return (
                                        <MenuItem value={elem?.value} key={elem?.code}>
                                            {elem?.value}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>
                                {UnicarePayload?.additionalInfo?.elements?.requestTypeCode?.required && !additionalInfo?.requestTypeCode && err ? UnicarePayload?.additionalInfo?.elements?.requestTypeCode?.errorMessage : 'Required *'}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <UnicareNextBtn handleSubmit={handleSubmit} setSelectedTab={setSelectedTab}  />
        </Paper>
    )
}

export default DefaultAdditionalInfoForm
