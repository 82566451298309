import { Grid } from '@mui/material'
import React from 'react'
import GreenButton from '../../claims/GreenButton'
import { sharedStyles } from '../../../sharedStyles'



const UnicareNextBtn = ({handleSubmit,noBack, setSelectedTab}) => {
  
  return (
    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      sx={sharedStyles?.justifyEnd}
                      gap={1}
                  >
                   {!noBack && <GreenButton onClick={() =>  setSelectedTab(prev => prev -1)} >
                           Back
                      </GreenButton>}
                      <GreenButton onClick={handleSubmit} >
                           Next
                      </GreenButton>
                  </Grid> 
  )
}

export default UnicareNextBtn