import React, { useEffect, useState } from 'react'
import MyTabs from '../../components/tabs/MyTabs'
import { Box } from '@mui/material'
import { useLocation, useSearchParams } from 'react-router-dom'
import { sharedStyles } from '../../sharedStyles';
import PharmaciesTable from './PharmaciesTable';
import RtbcRequest from './RtbcRequest';

const PharmacyTab = () => {
    const [searchParams] = useSearchParams();
    const preSelectedTabParam = searchParams.get('preSelectedTab');
    const [preSelectedTab, setPreSelectedTab] = useState(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
    const location = useLocation()
     
    const tabs =
    [
        {
            label: "Pharmacies", value: <PharmaciesTable />,
            button: 'New Search', changeTab: 1
        },
        {
            label: "RTBC Request", value: <RtbcRequest />, changeTab: 0, button: 'Back',
        },
    ]


    useEffect(() => {
        setPreSelectedTab(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
    }, [preSelectedTabParam]);
    return (
        <section key={location?.key}>
            <Box sx={sharedStyles?.tabWrapper}>
                <MyTabs tabs={tabs} preSelectedTab={preSelectedTab} />
            </Box>
        </section>
    )
}
export default PharmacyTab
