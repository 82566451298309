import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { styles } from './styles';

const ProfileRxDetails = ({ data }) => {
    return (
              <div>
                  <Button variant="contained" size='small' color="success">
                      <Link style={styles?.link} to={`/patientRxDetails/${data?.id || ''}`}>
                          View Details
                      </Link>
                  </Button>
              </div>
          );
  }

export const PatientRxColumnDefs = () => {
    return [
        { headerName: 'Drug Description', field: 'drugDescription', filter: true,  resizable: false,minWidth:350 },
        { headerName: 'Quantity', field: 'quantity', filter: true, width: 200, resizable: false },
        {
            headerName: 'Refills', field: 'refills', filter: true, width:230, resizable: true,
        },
        { headerName: 'Strength', field: 'strength', minWidth: 150, filter: true, resizable: true },
          { headerName: 'Details', field: '', cellRenderer: (params) => params?.data && ProfileRxDetails(params), minWidth: 140, resizable: false , flex:1},
    ];
};

export default PatientRxColumnDefs