import { Avatar, Card, CardContent, CardHeader, Chip, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { colors } from '../../utils/colors'
import { sharedStyles } from '../../sharedStyles'
import { styles } from './styles'
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';

const RxPrescriberDetails = ({prescriber}) => {
  return (
    <Grid item xs={12} md={6}>
    <Card sx={{ height: '100%' }}>
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: colors?.themePink }}><MedicalInformationIcon /></Avatar>}
        title="Prescriber Information" 
        sx={sharedStyles?.commonBottomBorder}
      />
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Dr. {prescriber?.Name.FirstName} {prescriber?.Name.LastName}
        </Typography>
        
        <List>
          <ListItem sx={styles?.patientListItem}>
            <ListItemText 
              primary="Communication" 
              secondary={
                prescriber?.CommunicationNumbers.map((comm, index) => (
                  <Chip
                    key={index}
                    label={comm.Qualifier} 
                    size="small" 
                    sx={sharedStyles?.mrt1}
                  />
                ))
              } 
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  </Grid>
  )
}

export default RxPrescriberDetails