import React, { useContext } from 'react'
import DefaultUnicareRenderingProviders from '../../components/Unicare/DefaultUnicareRenderingProviders'
import { UnicareContext } from '../../../../../context/priorAuthorizationForms/UnicareArContext';

const UnicareRenderingProvider = () => {
    const { setSelectedTab, onceCompleted, renderingProvider, setRenderingProvider, selectedTab } = useContext(UnicareContext);
  
    return (
    <DefaultUnicareRenderingProviders 
      onceCompleted={onceCompleted}
      renderingProvider={renderingProvider}
      setRenderingProvider={setRenderingProvider}
      setSelectedTab={setSelectedTab}
      selectedTab={selectedTab}
    />
  )
}

export default UnicareRenderingProvider
