import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react'
import HorizontalBox from '../../components/shared/HorizontalBox'
import { FiCheckCircle } from 'react-icons/fi';
import { colors } from '../../utils/colors';
import { styles } from './styles';
import { useAuth0 } from '@auth0/auth0-react';
import { clearAllLocalData } from '../../utils/localStorageFunctions';
import { logoutRedirection } from '../../utils/reusableFunctions';
import { logoutUserApiCall } from '../../services/userApis';

const SuccessModal = ({open}) => {
    const { logout } = useAuth0();

    const handleClose = async() => {
        await logoutUserApiCall()
        clearAllLocalData()
        logout({ logoutParams: { returnTo: logoutRedirection() } })
    }
    return (
        <Modal open={open} onClose={handleClose} aria-labelledby='confirmation-modal' aria-describedby='modal-confirmation-description'>
            <Box sx={styles?.modalWrapper}>
                <HorizontalBox>
                    <Typography id="modal-modal-title" sx={styles?.modalTitle} variant="h6" component="h2">
                        Success!
                    </Typography>
                    <FiCheckCircle color={colors?.themeGreen} />
                </HorizontalBox>
                <Typography id="modal-modal-description" sx={styles?.modalBody}>
                    Thank you for completing the questionnaire. Your responses have been submitted successfully, and we will review them shortly.
                </Typography>
                <Box sx={styles?.buttonWrapper}>
                    <Button  variant='contained' onClick={handleClose} color='success'>OK</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default SuccessModal