import React, { useState } from 'react';
import Select from 'react-select';
import { colors } from '../../utils/colors';

const PaginatedSearchSelect = ({
  options,
  onSearch,
  onLoadMore,
  isLoading,
  hasMore,
  placeholder = 'Search...',
  isMulti,
  error,
  onChange,
  value,
  color,
  errorMessage
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    onSearch && onSearch(newValue); 
  };

  const handleMenuScrollToBottom = () => {
    if (!isLoading && hasMore) {
      onLoadMore && onLoadMore(); 
    }
  };
  return (
<div>
<Select
    onChange={onChange}
    value={value}
  options={options}
  onInputChange={handleInputChange}
  isLoading={isLoading}
  onMenuScrollToBottom={handleMenuScrollToBottom}
  placeholder={placeholder}
  noOptionsMessage={() => (isLoading ? 'Loading...' : 'No options')}
  isSearchable
  isClearable
  isMulti={isMulti}
  styles={{
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    control: (provided, state) => ({
      ...provided,
      padding: '9px',
      border: state.isFocused ? `2px solid ${error ? colors?.errorRed : color || colors?.green}` : `1px solid ${error ? colors?.errorRed : provided.borderColor}`,
      boxShadow: state.isFocused ? `0px 0px 1px ${color || colors?.green}` : `none`,
      "&:hover": {
        border: `${ error? `1px solid ${colors?.errorRed}`: state?.isFocused ? `2px solid ${color || colors?.green}` : `1px solid ${colors?.black}`}`,
      },
      
    }),
    placeholder: (provided) => ({
      ...provided,
      color: error ? colors?.errorRed : provided.color, // Make placeholder red if error is true
    }),
  }}
  
/>
 {error && (
  <span style={{ color: colors?.errorRed, fontSize: '12px', marginTop: '4px', display: 'block' }}>
    {errorMessage || 'This field is required'}
  </span>
)}
</div>

  );
};

export default PaginatedSearchSelect;