import React, { useContext } from 'react'
import { getAllCPTcodes } from '../../../../../services/pVerify'
import toast from 'react-hot-toast';
import { paFormErrors } from '../../../../../utils/staticData';
import DefaultProcedures from '../../components/MercyCare/DefultProcedures';
import { MercyGroupContext } from '../../../../../context/priorAuthorizationForms/MercyCareContext';

const MercyCareProcedures = () => {
    const { procedureInfo, setProcedureInfo, setSelectedTab, onceCompleted } = useContext(MercyGroupContext)

    const fetchData = async () => {
        const data = await getAllCPTcodes();
        return data?.data?.data || [];
    };

    const handleNext = (submit) => {
        if (procedureInfo?.length) {
            submit ? setSelectedTab(onceCompleted) : setSelectedTab(prev => prev + 1)
        }
        else {
            toast?.error(paFormErrors?.proceduresImp)
        }
    };
    return (
        <DefaultProcedures
            title="Procedures Information"
            fetchData={fetchData}
            onRowSelected={(row) => setProcedureInfo((prev) => [...prev, row])}
            onRemove={(id) => setProcedureInfo((prev) => prev.filter((item) => item.id !== id))}
            selectedData={procedureInfo}
            setSelectedData={setProcedureInfo}
            onNext={handleNext}
            maxSelections={5}
            setSelectedTab={setSelectedTab}
            onceCompleted={onceCompleted}
        />
    )
}

export default MercyCareProcedures
