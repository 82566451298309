import React, { useContext, useState } from 'react'
import ImpDatesForm from '../../components/CommonDatesForms.jsx/ImpDatesForm'
import { paFormErrors } from '../../../../../utils/staticData'
import dayjs from 'dayjs'
import { FloridaBlueArContext } from '../../../../../context/priorAuthorizationForms/FloridaBlueArContext'

const DatesInfoBcbsIllinoisHsForm = () => {
    const today = dayjs();
    const maxDate = today.add(2, 'years');
    const minDate = today.subtract(12,'months')
    const [dateLimits] = useState({ min: minDate, max: maxDate})
    const { datesInfo: formData, setDatesInfo: setFormData, setSelectedTab, onceCompleted } = useContext(FloridaBlueArContext)
    const [errors, setErrors] = useState({ fromDate: false, toDate: false })

    const handleSubmit = (submit) => {
        const { toDate, fromDate } = formData
        if ((toDate && dayjs(toDate).isBefore(dayjs(fromDate))) || !fromDate) {
            setErrors({
                fromDate: fromDate ? false : paFormErrors?.reqField,
                toDate: !toDate 
                ? false
                : dayjs(toDate).isBefore(dayjs(fromDate))
                    ? paFormErrors?.inValidToDate 
                    : false,
            })
            return
        }
        else {
            setErrors({
                fromDate: false,
                toDate: false,
            })
            submit && onceCompleted ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
        }

    }
    return (
        <ImpDatesForm
            handleSubmit={handleSubmit}
            dateLimits={dateLimits}
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            onceCompleted={onceCompleted}
            setSelectedTab={setSelectedTab}
            notImpDates={['toDate']}
            showRadioButtons
        />
    )
}

export default DatesInfoBcbsIllinoisHsForm