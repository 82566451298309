import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { styles } from '../styles'
import { sharedStyles } from '../../../../../sharedStyles'
import UnicareNextBtn from '../../UnicareNextBtn'
import { handleChangePaRegexs } from '../../handleChangeRegexes'


const DefaultProviderNotes = ({ providerNotes, setProviderNotes, setSelectedTab, onceCompleted }) => {
      const [notes, setNotes] = useState(providerNotes);
    
            const handleChange = (event, index) => {
                const { value } = event.target;
                const updatedNotes = [...notes];
                updatedNotes[index] = value;
        
                const regex = handleChangePaRegexs?.aetna?.message;
                if (regex && value !== "" && !regex.test(value)) {
                    return;
                }
        
                setNotes(updatedNotes); // Update the state with the modified note
            };
        
            
        
            const addNewNote = () => {
                setNotes([...notes, ""]); // Add a new empty note
            };
        
            const handleSubmit = (submit) => {
                setSelectedTab((prev) => prev + 1);
                setProviderNotes(notes); // Update the providerNotes state with the notes
            };
    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Provider Notes
            </Typography>

            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>
                    {notes?.map((note, index) => (
                        <Grid item xs={12} key={index}>
                            <TextField
                                fullWidth
                                label={`Provider Note ${index + 1}`}
                                value={note}
                                onChange={(event) => handleChange(event, index)}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Box mt={2}>
                    <Button variant="contained" color="primary" onClick={addNewNote}>
                        Add New Provider Note
                    </Button>
                </Box>
            </Box>

            <UnicareNextBtn handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
        </Paper>
    )
}

export default DefaultProviderNotes