import React, { useContext } from 'react'
import { MercyGroupContext } from '../../../../../context/priorAuthorizationForms/MercyCareContext'
import DefaultAdditionalInfo from '../../components/MercyCare/DefaultAdditionalInfo'

const MercyCareAdditionalInfo = () => {
    const {additionalInfo, setAdditionalInfo, setSelectedTab, selectedTab} = useContext(MercyGroupContext)
  return (
    <DefaultAdditionalInfo 
    additionalInfo={additionalInfo}
    selectedTab={selectedTab}
    setAdditionalInfo={setAdditionalInfo}
    setSelectedTab={setSelectedTab}
    />
  )
}

export default MercyCareAdditionalInfo