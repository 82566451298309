import axios from "axios";
import toast from "react-hot-toast";
import { failedRequestStr } from "../utils/staticData";
import { clearAllLocalData } from "../utils/localStorageFunctions";
import { getRefreshToken, logoutRedirection } from "../utils/reusableFunctions";

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

export const handleLogout = async () => {
  await logoutUserApiCall()
  clearAllLocalData()
  const logoutUrl = `https://${auth0Domain}/v2/logout?client_id=${clientId}&returnTo=${encodeURIComponent(logoutRedirection())}`;
  window.location.href = logoutUrl;
}


export const userPostAxios = async (url, payload, contentType,retry = true,options) => {
  try {
    const token = localStorage.getItem('authToken')
    const response = await axios.post(url, payload, {
      headers: {
        'Content-Type': contentType ?? 'application/json',
        'Authorization': `Bearer ${token}` 
      },
      ...options,
    });
    return response
  }
  catch (error) {
    
    if(error?.response?.status === 401 && retry){
      return await  handleRefreshTokenApi(() => userPostAxios(url, payload, contentType,false))
     
    }
    else if(error?.response?.status === 401 &&  !retry){
       handleLogout()
    }
    else toast.error(failedRequestStr)
  }
}

export const userPutAxios = async (url, payload,retry=true) => {
  try {
    const token = localStorage.getItem('authToken')
    const response = await axios.put(url, payload, {
      headers: {
        'Content-Type': 'application/json',
         'Authorization': `Bearer ${token}`
      },
    });
    return response
  }
  catch (error) {
    if(error?.response?.status === 401 && retry){
      return await  handleRefreshTokenApi(() => userPutAxios(url, payload,false))
     
    }
    else if(error?.response?.status === 401 &&  !retry){
       handleLogout()
    }
    else  toast.error(failedRequestStr)
  }
}

export const userGetAxios = async (url, onlyResponse, params,retry = true) => {
  try {
    const token = localStorage.getItem('authToken')
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization':  `Bearer ${token}` 
      },
      params
    });
    return onlyResponse ? response : response?.data;
  } catch (error) {
    if(error?.response?.status === 401 && retry){
      return await  handleRefreshTokenApi(() => userGetAxios(url,onlyResponse, params,false))
     
    }
    else if(error?.response?.status === 401 &&  !retry){
       handleLogout()
    }
   else console.log('Error fetching data:', error);
  }
}

export const userDeleteAxios = async (url,retry=true) => {
  try {
    const token = localStorage.getItem('authToken')
    const response = await axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response
  } catch (error) {
    if(error?.response?.status === 401 && retry){
      return await handleRefreshTokenApi(() => userDeleteAxios(url,false))
     
    }
    else if(error?.response?.status === 401 &&  !retry){
       handleLogout()
    }
   else console.log(error, 'Failed to delete');
  }
}

const handleRefreshTokenApi = async (func) => {
  try {
     const response = await axios.get(`${process.env.REACT_APP_API_URL_NEW}/api/Auth0/RefreshToken?refreshToken=${getRefreshToken()}`)

    if (response?.data?.data) {
      const { access_token, refresh_token } = response?.data?.data
      access_token && localStorage.setItem('authToken', access_token)
      refresh_token && localStorage.setItem('refreshToken', refresh_token)
     return await func()
    }
  }
  catch (e) {
    handleLogout()
    console.log(e)
  }
}


export const logoutUserApiCall = async() => {
  const accessToken = localStorage.getItem('authToken')
  try{
    const response = await axios.post(`${process.env.REACT_APP_API_URL_NEW}/api/Auth0/RevokeToken`,{
      refreshToken:getRefreshToken(),
      accessToken
    })
  }
  catch(e){
    console.log(e)
  }
}