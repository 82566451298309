import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import React from 'react'
import { styles } from './styles'

const RxMessageDetails = ({Header}) => {
    return (
         <Grid item xs={12}>
        <Card>
            <CardHeader
                title="Message Information"
                sx={styles.cardHeader}
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle2" color="text.secondary">Message ID</Typography>
                        <Typography variant="body1">{Header?.MessageID?.substring(0, 13)}...</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle2" color="text.secondary">Sent Time</Typography>
                        <Typography variant="body1">{new Date(Header?.SentTime)?.toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle2" color="text.secondary">From</Typography>
                        <Typography variant="body1">{Header?.From?.Value} ({Header?.From?.Qualifer})</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle2" color="text.secondary">To</Typography>
                        <Typography variant="body1">{Header?.To?.Value} ({Header?.To?.Qualifer})</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        </Grid>
    )
}

export default RxMessageDetails