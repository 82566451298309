import React, { useContext } from 'react'
import { UnicareContext, UNICARECONTEXTProvider } from '../../../../../context/priorAuthorizationForms/UnicareArContext'
import UnicareArPatientInfoForm from './UnicareArPatientInfoForm'
import UnicareArSubscriberForm from './UnicareArSubscriberForm'
import UnicareArProviderNotes from './UnicareArProviderNotes'
import UnicareRenderingProvider from './UnicareRenderingProvider'
import UnicareArRequestingProviderForm from './UnicareArRequestingProviderForm'
import UnicareArDatesInfo from './UnicareArDatesInfo'
import UnicareArAdditionalInfo from './UnicareArAdditionalInfo'
import UnicareProceduresForm from './UnicareProceduresForm'
import UnicareDiagnosisForm from './UnicareDiagnosisInfo'
import FormDetailsUnicare from '../../components/FormDetailsUnicare'


const UnicareArForms = () => {

  return (
      <UNICARECONTEXTProvider>
        <AllUnicareForms />
      </UNICARECONTEXTProvider>
  )
}

const AllUnicareForms = () => {
  const { selectedTab, setSelectedTab, handleSubmit } = useContext(UnicareContext);
 const { patientInfo, subscriberInfo, providerNotes, renderingProvider, requestingProvider, datesInfo, additionalInfo, diagnosisInfo } = useContext(UnicareContext);
  const forms = [
    <UnicareArPatientInfoForm />,
    <UnicareArSubscriberForm />,
    <UnicareArProviderNotes />,
    <UnicareRenderingProvider />,
    <UnicareArRequestingProviderForm />,
    <UnicareArDatesInfo />,
    <UnicareArAdditionalInfo />,
    <UnicareProceduresForm />,
    <UnicareDiagnosisForm />,
    <FormDetailsUnicare
      data={{
        patientInfo, subscriberInfo, providerNotes, renderingProvider, requestingProvider, datesInfo, additionalInfo, diagnosisInfo 
      }}
      setSelectedTab={setSelectedTab}
      onSubmit={handleSubmit}
      title={'Form Details'}
    />
  ]

  return forms[selectedTab] || null

}


export default UnicareArForms
